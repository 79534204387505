import React, { useState } from "react";
import styled from "styled-components";
import {
  InfiniteHits,
  ClearRefinements,
  RefinementList,
  Configure,
  PoweredBy,
} from "react-instantsearch-dom";
import { ResponsiveFlex, Flex } from "components/Flex";
import { ItemCard } from "components/ProductGrid";
import useTitle from "hooks/useTitle";

export const SideBar = styled.div`
  min-width: 240px;
  margin-right: 20px;
  @media (max-width: 776px) {
    display: none;
  }
`;

export const MobileAdvancedSearch = styled.div`
  display: none;

  @media (max-width: 776px) {
    display: block;
    button {
    }
    main {
      border: 1px solid #c1c1c1;
      transition: all 0.3s;
      height: 360px;
      overflow: hidden;
    }
    main.closed {
      height: 0px;
      border: 0;
    }
  }
`;

const SearchContent = styled.div`
width: 100%;
.ais-InfiniteHits {
  width: 100%;
}
`

export const SearchPage = ({ cart, setCart }) => {
  useTitle("Search")
  const [isMobileAdvancedSearchOpen, setIsMobileAdvancedSearchOpen] = useState(false);
  return (
    <div style={{ margin: 20 }}>
        <ResponsiveFlex>
          <SideBar>
            <ClearRefinements />
            <h2>Stores</h2>
            <RefinementList searchable attribute="store" />
            <h2>Cities</h2>
            <RefinementList searchable attribute="city" />
            <h2>Categories</h2>
            <RefinementList searchable attribute="category" />
            <PoweredBy />
          </SideBar>
          <SearchContent>
            <br />
            <MobileAdvancedSearch>
              <button onClick={() => setIsMobileAdvancedSearchOpen(!isMobileAdvancedSearchOpen)}>
                Advanced Search
              </button>
              <main className={isMobileAdvancedSearchOpen ? "" : "closed"}>
                <ClearRefinements />
                <h2>Stores</h2>
                <RefinementList searchable attribute="store" />
                <h2>Cities</h2>
                <RefinementList searchable attribute="city" />
                <h2>Categories</h2>
                <RefinementList searchable attribute="category" />
                <PoweredBy />
              </main>
            </MobileAdvancedSearch>
            <br />
            <br />
            <Flex>
              <Configure hitsPerPage={10} />
              <InfiniteHits
                hitComponent={({ hit }) => {
                  const count = Number.parseFloat(
                    (cart[hit.locationId] &&
                      cart[hit.locationId] &&
                      cart[hit.locationId][hit.variationId]) ||
                      0
                  );
                  return (
                    <ItemCard
                      {...hit}
                      count={count}
                      setCart={setCart}
                      cart={cart}
                    />
                  );
                }}
              />
            </Flex>
            <br />
          </SearchContent>
        </ResponsiveFlex>
    </div>
  );
};
export default SearchPage;
