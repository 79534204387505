import React from "react";

export default ({ width="424", height="258", ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 424 258"
    fill="none"
    {...props}
  >
    <g>
      <g>
        <path d="M389.758 10H14V220H389.758V10Z" fill="white" />
        <path d="M389.758 115H14V220H389.758V115Z" fill="#BF0A30" />
        <path d="M139.253 10H14V220H139.253V10Z" fill="#002868" />
        <path
          d="M76.8005 83.208L85.2758 105.075H112.703L90.5139 118.589L98.9892 140.456L76.8005 126.941L54.6118 140.456L63.0871 118.589L40.8984 105.075H68.3252L76.8005 83.208Z"
          fill="white"
        />
      </g>
      <rect
        x="14.5"
        y="10.5"
        width="374.758"
        height="209"
        rx="4.5"
        stroke="black"
      />
    </g>
  </svg>
);
