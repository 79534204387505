import React from "react";
import styled from "styled-components";
import StarBand from 'components/StarBand';

export const Footer = styled.div`
  padding: 1px 0 0;
  position: relative;
  background-image: url("https://static.overlay-tech.com/assets/c18a21f6-89ca-459c-a0e1-c4e28f01dd01.png");
  background-color: ${props => props.theme.colors.bamBlue};
  overflow: hidden;
`;
export const Copyright = styled.p`
  width: 427px;
  font-family: ${props =>
    props.theme.fonts.roboto31Bold.family};
  font-size: ${props =>
    props.theme.fonts.roboto31Bold.size};
  font-weight: ${props =>
    props.theme.fonts.roboto31Bold.weight};
  line-height: ${props =>
    props.theme.fonts.roboto31Bold.lineHeight};
  color: ${props => props.theme.colors.white};
  text-align: center;
  width: 100%;
  font-size: 31px;
  font-weight: 700;
  line-height: normal;
  color: rgba(255,255,255,1);
  text-align: center;
`;
export const FooterLinks = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 20px;
  @media (max-width: 776px) {
    flex-direction: column;
  }
`;
export const FooterLinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:not(:last-of-type) {
    margin: 0 auto;
  }
  @media (max-width: 776px) {
    margin: 0 auto;
    width: 220px;
  }
`;
export const FooterTitle = styled.p`
  font-family: ${props =>
    props.theme.fonts.roboto26Bold.family};
  font-size: ${props =>
    props.theme.fonts.roboto26Bold.size};
  font-weight: ${props =>
    props.theme.fonts.roboto26Bold.weight};
  line-height: ${props =>
    props.theme.fonts.roboto26Bold.lineHeight};
  color: ${props => props.theme.colors.white};
  text-align: center;
  margin-bottom: 22px;
`;
export const Link = styled.a`
  font-family: ${props =>
    props.theme.fonts.roboto22Light.family};
  font-size: ${props =>
    props.theme.fonts.roboto22Light.size};
  font-weight: ${props =>
    props.theme.fonts.roboto22Light.weight};
  line-height: ${props =>
    props.theme.fonts.roboto22Light.lineHeight};
  color: ${props => props.theme.colors.white};
  text-align: center;
  &:not(:last-of-type) {
    margin-bottom: 22px;
  }
`;

const FooterComponent = () => {
  return (
    <Footer>
      <StarBand style={{ marginTop: 12 }} />
      <Copyright>
        Buy American Made, LLC 2020
      </Copyright>
      <FooterLinks>
        <FooterLinksColumn>
          <FooterTitle>Our Cities</FooterTitle>
          <Link href="/browse/76448">Eastland, TX</Link>
          <Link href="/browse/76401">Stephenville, TX</Link>
        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterTitle>Merchants</FooterTitle>
          <Link href="/merchant">Sell on BuyBam</Link>
          <Link href="/merchant">What We Offer</Link>
        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterTitle>About Us</FooterTitle>
          <Link href="/contact">Contact Us</Link>
          <Link href="/about">Who We Are</Link>
        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterTitle>Follow Us</FooterTitle>
          <Link href="https://www.facebook.com/Buybamcom-101423238497674">Facebook</Link>
          {/* <Link>Instagram</Link> */}
        </FooterLinksColumn>
        <FooterLinksColumn>
          <FooterTitle>Site Info</FooterTitle>
          <Link href="/tos">Terms Of Service</Link>
          <Link href="/privacy">Privacy Policy</Link>
          <Link>Website Accessibility</Link>
        </FooterLinksColumn>
      </FooterLinks>
    </Footer>
  );
};

export default FooterComponent;