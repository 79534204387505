import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import LogRocket from "logrocket";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from 'react-redux'
import store from 'store'

LogRocket.init("tpuq5o/bam", {
  mergeIframes: true,
  network: {
    requestSanitizer: (request) => {
      // const body = JSON.parse(request.body);

      // const sensitiveWords = ['password', 'nonces', 'jwt'];
      // if (body) {
      //   for (const word of sensitiveWords) {
      //     body[word] = '**removed for privacy**';
      //   }
      //   request.body = JSON.stringify(body);
      // }

      // // The entire registration form
      // const sensitivePaths = ['/api/register', '/api/login', '/api/checkout'];
      // if (sensitivePaths.any(path => request.url.endsWith(path))){
      //   debugger;
      //   // scrub out the body
      //   request.body = null;
      // }

      // TODO Create an allow list instead of a block list. Too much data getting leaked
      request.body = null;
      return request;
    },
    responseSanitizer: (response) => {
      response.body = null;
      return response;
    },
  },
});

const GlobalStyle = createGlobalStyle`
body {
  background: #F5F5F5;
  height: 100%;
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
}


.ais-Hits-item, .ais-InfiniteHits-item, .ais-InfiniteResults-item, .ais-Results-item {
  margin-top: 0;
  margin-left: 0;
  padding: 0;
  width: inherit;
  border: 0;
  box-shadow: none;
}
.ais-InfiniteHits-list {
  justify-content: center;
}

.ais-InfiniteHits-loadMore {
  line-height: 18px;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  padding: 20px;
  font-size: 18px;
  font-size: 28px;
  background-color: rgba(0,40,104,1);
  color: white;
  border: 0;
  outline: 0;
  border-radius: 70px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 70px;
}
`;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <Provider store={store}>
        <GlobalStyle whiteColor />
        <BrowserRouter>
          <Auth0Provider
            audience={process.env.REACT_APP_AUTH0_BASE_URL}
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={`${process.env.REACT_APP_AUTH0_BASE_URL}/callback`}
            scope="openid profile email"
            useRefreshTokens={true}
            cacheLocation="localstorage"
          >
            <App />
          </Auth0Provider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
