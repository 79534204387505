import React, { useState } from "react";
import styled from "styled-components";
import { TOOLBAR_HEIGHT, BUTTON_HEIGHT } from "../constants";
import _keyby from "lodash.keyby";
import AddToCartPill from "components/AddToCartPill";
import { Link } from 'react-router-dom';

export const Grid = styled.ul`
  padding: 0px;
  margin-top: ${TOOLBAR_HEIGHT + 10}px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Card = styled.li`
  position: relative;
  background: white;
  width: 310px;
  margin: 6px;
  box-shadow: 0 0 2px 0 rgba(27, 33, 38, 0.16),
    0 2px 2px 0 rgba(27, 33, 38, 0.32);
  border-radius: 42px;
  /* overflow: hidden; TODO Fix*/
  min-height: 340px;
`;

const Price = styled.div`
  font-weight: 600;
  font-size: 20px;
  position: absolute;
  left: 4px;
  top: 142px;
  width: 149px;
  background-color: #0d47a1;
  color: white;
  height: ${BUTTON_HEIGHT}px;
  border-radius: 4px;
  line-height: ${BUTTON_HEIGHT}px;
  text-align: center;
`;

const Name = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin: 10px;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const Image = styled.img`
  width: 310px;
  height: 160px;
  border-bottom: 1px solid #c1c1c1;
  border-top-right-radius: 42px;
  border-top-left-radius: 42px;
`;

const Description = styled.a``;
const Desc = styled.div`
  margin: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: #424242;
`;

const Claims = styled.div`
  color: #616161;
  font-weight: 800;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  position: relative;
  p {
    margin: 14px 10px 10px 10px;
  }
  div {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 80px;
    white-space: nowrap;
    padding-bottom: 12px;
  }
  svg {
    margin: 6px;
    height: 72px;
    width: 72px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
  }
`;

const ShowMore = styled.button`
  color: #606060;
  margin-top: 8px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.007px;
  text-transform: uppercase;
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
`;
const ResetSelect = styled.select`
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
`;
const ProductVariationPicker = styled(ResetSelect)`
  /* styling */
  width: 90%;
  margin: 0 5% 5% 5%;
  background-color: white;
  border: thin solid grey;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
`;
const AddToCartPillBottom = styled(AddToCartPill)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const CustomizeLink = styled(Link)`
  text-align: center;
  display: block;
  font-size: 28px;
  background-color: rgba(0,40,104,1);
  color: white;
  border: 0;
  outline: 0;
  border-radius: 70px;
  -webkit-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 70px;
  width: 100%;
  line-height: 66px;
  position: absolute;
  bottom: 0;
`

export const ProductGridCard = ({ product, cart, setCart }) => {
  const [variationId, setVariationId] = useState(
    product.itemData?.variations[0]?.id
  );
  const variation = product.itemData?.variations.find(
    (v) => v.id === variationId
  );
  // const customAttributes = _keyby(
  //   variation.customAttributeValues,
  //   (x) => x.name
  // );
  const step = Number.parseFloat(variation.customAttributeValues.step) || 1;
  const minAmount = Number.parseFloat(variation.customAttributeValues.min_amount) || 1;
  const maxAmount = Number.parseFloat(variation.customAttributeValues.max_amount) || 9;

  const count = Number.parseFloat(cart[product.locationId] && cart[product.locationId] && cart[product.locationId][variation.id] || 0);
  const price = variation?.itemVariationData?.priceMoney
    ? (
        Math.round(variation?.itemVariationData?.priceMoney?.amount) / 100
      ).toFixed(2)
    : (Math.round(product?.price * 100) / 100).toFixed(2);

  return (
    <Card key={product.sku}>
      <Link to={`/shop/${product.locationId}/${product.id}`}>
        <Image src={product.img} />
        <Name>{product.name}</Name>
        {product.itemData?.variations.length === 1 && (
          <Desc>{product.description}</Desc>
        )}
      </Link>
      {product.itemData?.variations.length > 1 && (
        <ProductVariationPicker
          value={variationId}
          onChange={(e) => setVariationId(e.currentTarget.value)}
        >
          {product.itemData?.variations
            .filter(
              ({ itemVariationData }) => itemVariationData?.priceMoney
            )
            .map(({ itemVariationData, id }) => (
              <option key={id} value={id}>
                {itemVariationData.name} - $
                {(itemVariationData?.priceMoney?.amount / 100).toFixed(2)}
              </option>
            ))}
        </ProductVariationPicker>
      )}
      {product.itemData?.modifierListInfo?.length > 1 ? (
        <CustomizeLink to={`/shop/${product.locationId}/${product.id}`}>Customize</CustomizeLink>
      ) : (
        <AddToCartPillBottom
          step={step}
          price={price}
          minAmount={minAmount}
          maxAmount={maxAmount}
          count={count}
          onChange={value => {
            const newValue = Number.parseFloat(value);
            if (newValue === 0) {
              if (Object.keys(cart[product.locationId]).length === 1) {
                setCart({ ...cart, [product.locationId]: undefined })
              } else {
                setCart({
                  ...cart,
                  [product.locationId]: {
                    ...cart[product.locationId],
                    [variation.id]: undefined
                  }
                })
              }
            } else {
              setCart({...cart, [product.locationId]: { ...cart[product.locationId], [variation.id]: newValue }})
            }
          }}
        />
      )}

    </Card>
  );
};

export const ItemCard = ({ 
  objectID="", 
  name="", 
  description="", 
  img="", 
  locationId="", 
  variationId="", 
  step = .25, 
  minAmount = 1, 
  maxAmount = 10, 
  count = 0, 
  price, 
  cart, 
  setCart
}) => {
  return (
    <Card key={objectID}>
      <a href={`/shop/${locationId}/${objectID}`}>
        <Image src={img} />
        <Name>{name}</Name>
          <Desc>{description}</Desc>
      </a>
      <AddToCartPillBottom
        step={step}
        price={price}
        minAmount={minAmount}
        maxAmount={maxAmount}
        count={count}
        onChange={value => {
          const newValue = Number.parseFloat(value);
          if (newValue === 0) {
            if (Object.keys(cart[locationId]).length === 1) {
              setCart({ ...cart, [locationId]: undefined })
            } else {
              setCart({
                ...cart,
                [locationId]: {
                  ...cart[locationId],
                  [variationId]: undefined
                }
              })
            }
          } else {
            setCart({...cart, [locationId]: { ...cart[locationId], [variationId]: newValue }})
          }
        }}
      />
    </Card>
  );
}

export const ProductGrid = ({ products, cart, setCart }) => (
  <Grid>
    {products.map((product) => (
      <ProductGridCard key={product.id} product={product} cart={cart} setCart={setCart} />
    ))}
  </Grid>
);

/**
 * EXAMPLE: 
  <Product.Grid>
    {products.map(product => (
      <Product.Card key={product.sku}>
        <Product.Image src={product.imgWebp} />
        <Product.Price>${(Math.round(product.price * 100) / 100).toFixed(2)} {product.unit || "Each"}</Product.Price>
        <Product.Description>
          <Product.Name>{product.name}</Product.Name>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
        </Product.Description>
        <Product.Claims>Claims</Product.Claims>
      </Product.Card>
    ))}
  </Product.Grid>
 */
export const Product = {
  Grid,
  Card,
  Name,
  Image,
  Description,
  Price,
  Claims,
  ShowMore,
  ProductVariationPicker,
};

export default ProductGrid;
