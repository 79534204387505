import defaultProfile from "icons/img/default_profile.png";
import defaultCover from "icons/img/default_coverphoto.png";

import maesProfile from "icons/img/maes_profile.jpg";
import maesCover from "icons/img/maes_coverphoto.png";

import mjProfile from "icons/img/mj_profile.jpeg";
import mjCover from "icons/img/mj_coverphoto.jpeg";
import qs from 'qs'

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function profilePictureForCustomer(merchantId) {
  switch(merchantId) {
    case "ML9T1VS1J5ZA0": return mjProfile;
    case "MLD98C3MABRQX": return maesProfile;
    default: return defaultProfile;
  }
}

export function coverPhotoForCustomer(merchantId) {
  switch(merchantId) {
    case "ML9T1VS1J5ZA0": return mjCover;
    case "MLD98C3MABRQX": return maesCover;
    default: return defaultCover;
  }
}

export const generateCartCountRange = (
  step = 1,
  minAmount = 1,
  maxAmount = 9
) => {
  const steps = [0];
  for (let i = minAmount; i <= maxAmount; i += step) {
    if (Math.abs(i - Math.ceil(i)) < 0.1) i = Math.round(i);
    steps.push(parseFloat(i.toFixed(3)));
  }
  return steps;
};


// Returns a slug from the category name.
// Spaces are replaced by "+" to make
// the URL easier to read and other
// characters are encoded.
function getCategorySlug(name) {
  return name
    .split(' ')
    .map(encodeURIComponent)
    .join('+');
}

// Returns a name from the category slug.
// The "+" are replaced by spaces and other
// characters are decoded.
function getCategoryName(slug) {
  return slug
    .split('+')
    .map(decodeURIComponent)
    .join(' ');
}

export const createURL = state => {
  const isDefaultRoute =
    !state.query &&
    state.page === 1 &&
    (state.refinementList && state.refinementList.brand.length === 0) &&
    (state.menu && !state.menu.categories);

  if (isDefaultRoute) {
    return '/search';
  }

  const categoryPath = state.menu.categories
    ? `${getCategorySlug(state.menu.categories)}/`
    : '';
  const queryParameters = {};

  if (state.query) {
    queryParameters.query = encodeURIComponent(state.query);
  }
  if (state.page !== 1) {
    queryParameters.page = state.page;
  }
  if (state.refinementList.brand) {
    queryParameters.brands = state.refinementList.brand.map(encodeURIComponent);
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  return `/search/${categoryPath}${queryString}`;
};

export const searchStateToUrl = searchState =>
  searchState ? createURL(searchState) : '';

export const urlToSearchState = location => {
  const pathnameMatches = location.pathname.match(/search\/(.*?)\/?$/);
  const category = getCategoryName(
    (pathnameMatches && pathnameMatches[1]) || ''
  );
  const { query = '', page = 1, brands = [] } = qs.parse(
    location.search.slice(1)
  );
  // `qs` does not return an array when there's a single value.
  const allBrands = Array.isArray(brands) ? brands : [brands].filter(Boolean);

  return {
    query: decodeURIComponent(query),
    page,
    menu: {
      categories: decodeURIComponent(category),
    },
    refinementList: {
      brand: allBrands.map(decodeURIComponent),
    },
  };
};
