import styled from 'styled-components'

const Input = styled.input`
  background-color: #fafbfc;
  background-position: right 8px center;
  background-repeat: no-repeat;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  box-shadow: inset 0 1px 0 rgba(225, 228, 232, 0.2);
  color: #24292e;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  padding: 5px 12px;
  vertical-align: middle;
  margin-right: 5px;
  max-width: 100%;
  width: 320px;
`

const Dl = styled.dl`
  margin: 15px 0;
`
const Dt = styled.dt`
  margin: 0 0 6px;
`
const Dd = styled.dd`
  margin-left: 0;
`
const Label = styled.label`
  font-weight: 600;
  color: #24292e;
`

const TextField = ({ name, value, onChange, ...props }) => {
  return (
    <Dl>
      <Dt for={name}>
        <Label>{name}</Label>
      </Dt>
      <Dd>
        <Input name={name} onChange={onChange} value={value} {...props} />
      </Dd>
    </Dl>
  );
}

export default TextField;
