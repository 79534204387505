import React from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import Location from "icons/Location";
import ProductGrid from "components/ProductGrid";
import useTitle from 'hooks/useTitle';

const Header = styled.header`
    width: 100%;
    background-color: white;
    position: relative;
`

const PageWidth = styled.div`
    margin: 0 auto;
    width: 90%;
    max-width: 1400px;
`

const StoreName = styled.h1`
    margin: 0;
`

const StoreAddress = styled.p`
    line-height: 18px;
`

const LocationBumpedDownALittle = styled(Location)`
    position: relative;
    top: 2px;
`

const ProfilePicture = styled.img`
    bottom: 64px;
    position: absolute;
    border-radius: 100%;
    width: 150px;
    height: 150px;
    margin-left: 6px;
`

const MarginLeft150 = styled.div`
    margin-left: 150px;
`

const StoreCatelog = ({ cart, setCart, location }) => {
    useTitle(location?.name);
    const catalogReq = useFetch(`${process.env.REACT_APP_BAM_API_URL}/api/catalog`, { query: { locationId: location.id || "" } });
    const products = catalogReq.response?.items?.filter(({ itemData }) => !(itemData.hasOwnProperty('ecomAvailable') && !itemData.ecomAvailable)) || [];

    return (
        <div>
            <Header>
                <ProfilePicture src={location.square_metadata.logoUrl} />
                <br />
                <PageWidth>
                    <MarginLeft150>
                        <StoreName>{location?.name}</StoreName>
                        <StoreAddress><LocationBumpedDownALittle fill="black" size="16px" />&nbsp;{location?.address}, {location?.city}, {location?.state}</StoreAddress>
                    </MarginLeft150>
                </PageWidth>
                <br />
                <hr />
            </Header>
            <PageWidth>
                <ProductGrid products={products} cart={cart} setCart={setCart} />
            </PageWidth>
        </div>
    );
}

const StoreCatelogPage = ({ cart, setCart, locations }) => {
    const { locationId } = useParams();
    const location = locations.find(l => l.id === locationId)
    if (!location) {
        return "Loading";
    }
    return <StoreCatelog cart={cart} setCart={setCart} location={location} />;
};

export default StoreCatelogPage;