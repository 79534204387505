import React from "react";
import styled from 'styled-components';

const Inline = styled.div`
    display: inline;
`

export const ProductVariations = ({
  variation,
  variations,
  setVariationId,
}) => (
  <>
    <b>Variations</b>
    {variations
      .filter(({ itemVariationData }) => itemVariationData?.priceMoney)
      .map(({ id, itemVariationData: v }) => (
        <div>
          <Inline>
            <input
              type="radio"
              id={id}
              name="variations"
              value={id}
              checked={id === variation.id}
              onChange={(e) => setVariationId(e.target.value)}
            />
            <label htmlFor={id}>
              {v.name} - ${(v.priceMoney.amount / 100).toFixed(2)}
            </label>
          </Inline>
        </div>
      ))}
  </>
);

export default ProductVariations;
