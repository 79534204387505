import React, { useRef } from "react";
import styled from "styled-components";
import Close from "icons/Close";
import useCreatePortal from "../hooks/useCreatePortal";
import useClickOutside from "../hooks/useClickOutside";
import { TOOLBAR_HEIGHT } from "../constants";
import bamLogo from "icons/img/bam_logo.png";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const SidebarDialog = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  background: #212121;
  color: white;
  width: 100%;
  max-width: 480px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(27, 33, 38, 0.16),
    0 2px 2px 0 rgba(27, 33, 38, 0.32);
  min-height: 300px;
  height: 100%;
  z-index: 100;
  transition: 0.5s;
  transform: ${(props) =>
    props.isOpen ? "translateX(0)" : "translateX(-100%)"};
`;
const SidebarToolbar = styled.div`
  height: ${TOOLBAR_HEIGHT}px;
  top: 0;
  display: flex;
`;
const ToolbarPadding = styled.div`
  width: 80px;
`;
const CloseButton = styled.button`
  color: white;
  width: 80px;
  background: none;
  border: none;
  outline: none;
`;
const SidebarBody = styled.div`
  flex: 1;
  overflow-y: auto;
  background-image: url(${bamLogo});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
`;
const SidebarTitle = styled.p`
  flex: 1;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;
const StarList = styled.ul`
  line-height: 42px;
  margin-left: 30px;
  font-size: 32px;
  li {
    margin: 20px;
    list-style: none;
  }
  a > li {
    color: white;
  }
  li::before {
    margin-left: 20px;
    content: url("https://static.overlay-tech.com/assets/a83bb6ae-a393-4d6f-8cb6-39275fa9c0bf.svg");
    position: absolute;
    left: 0; /* Adjust this value so that it appears where you want. */
  }
`;

const GuestList = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <StarList>
      <Link to="/">
        <li>Home</li>
      </Link>
      <Link to="/browse">
        <li>All Shops</li>
      </Link>
      <Link onClick={() => loginWithRedirect()}>
        <li>Sign In</li>
      </Link>
      <Link to="/about">
        <li>Who We Are</li>
      </Link>
      <Link to="/contact">
        <li>Contact Us</li>
      </Link>
      <Link to="/merchant">
        <li>Merchants</li>
      </Link>
    </StarList>
  );
}

const UserList = () => (
  <StarList>
    <Link to="/">
      <li>Home</li>
    </Link>
    <Link to="/browse">
      <li>All Shops</li>
    </Link>
    <Link to="/profile">
      <li>My Account</li>
    </Link>
    <Link to="/history">
      <li>My Orders</li>
    </Link>
  </StarList>
);

export const Sidebar = ({ user, close, isOpen, openButtonRef, isMobile }) => {
  const createBodyPortal = useCreatePortal();
  const ref = useRef();
  useClickOutside(ref, close, openButtonRef);

  return createBodyPortal(
    <SidebarDialog isMobile={isMobile} isOpen={isOpen} ref={ref}>
      <SidebarToolbar>
        <CloseButton onClick={close}>
          <Close size="32" fill="white" />
        </CloseButton>
        <SidebarTitle>Buy American Made</SidebarTitle>
        <ToolbarPadding />
      </SidebarToolbar>
      <SidebarBody>
        {user ? <UserList /> : <GuestList />}
      </SidebarBody>
      {/* <img src={bamLogo} /> */}
    </SidebarDialog>
  );
};

export default Sidebar;
