import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export class RadioGroup extends React.Component {
  getChildContext() {
    const { name, selectedValue, onChange } = this.props;
    return {
      radioGroup: {
        name,
        selectedValue,
        onChange
      }
    };
  }

  render() {
    const { Component, name, selectedValue, children, ...rest } = this.props;
    return (
      <Component style={{ display: 'flex', flexWrap: 'wrap' }} role="radiogroup" {...rest}>
        {children}
      </Component>
    );
  }
}

RadioGroup.childContextTypes = {
  radioGroup: PropTypes.object
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  children: PropTypes.node.isRequired,
  Component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object
  ])
};

RadioGroup.defaultProps = {
  name: "",
  selectedValue: "",
  Component: "div"
};

// eslint-disable-next-line react/no-multi-comp
export class Radio extends React.Component {
  render() {
    const { name, selectedValue } = this.context.radioGroup;
    const { onChange, value, labelText, ...args } = this.props;
    let checked = false;

    if (selectedValue !== undefined) {
      checked = value === selectedValue;
    }

    return (
      <Root>
        <Input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          aria-checked={checked}
          onClick={onChange}
          {...args}
        />
        <Fill />
        <div style={{ marginLeft: '25px' }}>{labelText}</div>
      </Root>
    );
  }
}

Radio.contextTypes = {
  radioGroup: PropTypes.object
};

Radio.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  labelText: PropTypes.string
};

Radio.defaultProps = {
  onChange: () => {},
  value: "",
  labelText: ""
};

const Root = styled.div`
  width: ${(props) => (props.size ? props.size : 120)}px;
  height: ${(props) => (props.size ? props.size : 120)}px;
  position: relative;
  margin: 2px; 

  &::before {
    content: "";
    border-radius: 4px;
    border: 1px solid
      ${(props) => (props.borderColor ? props.borderColor : "#DDD")};
    background: ${(props) =>
      props.backgroundColor ? props.backgroundColor : "#FAFAFA"};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

const Fill = styled.div`
  background: ${(props) => (props.fillColor ? props.fillColor : "#4CAF50")};
  width: 0;
  height: 0;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: "";
    opacity: 0;
    width: calc(120px - 4px);
    position: absolute;
    height: calc(120px - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid
      ${(props) => (props.borderActive ? props.borderActive : "#4CAF50")};
    border-radius: 4px;
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

const Relative = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 9001;
  width: 120px;
`;