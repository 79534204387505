import React from "react";
import styled from "styled-components";
import useFetch from "hooks/useFetch";
import useTitle from "hooks/useTitle";
import { ProductGridCard } from "components/ProductGrid";
import StoreCard from "components/StoreCard";
import { useParams } from "react-router-dom";
import ClosedSign from "icons/ClosedSign";
import { Link } from "react-router-dom";

const Flex = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  list-style: none;
  padding: 20px 0;
`;

const Carousel = styled(Flex)`
  list-style: none;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  list-style: none;
  height: 330px;
  margin-top: 6px;
  margin-left: 20px;
  padding-left: 440px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const LocationRow = ({ location, cart, setCart }) => {
  const catalogReq = useFetch(
    `${process.env.REACT_APP_BAM_API_URL}/api/catalog`,
    { query: { locationId: location.id || "" } }
  );
  const products = catalogReq.response?.items?.filter(({ itemData }) => !(itemData.hasOwnProperty('ecomAvailable') && !itemData.ecomAvailable)) || [];

  return (
    <Flex>
      <StoreCard href={`/shop/${location.id}`} location={location} />
      <Carousel>
        {products.map((product) => (
          <ProductGridCard key={product.id} product={product} cart={cart} setCart={setCart} />
        ))}
      </Carousel>
    </Flex>
  );
};

const ClosedSignWrapper = styled.div`
  margin: 0 auto;
  max-width: 300px;
`
const EmptyStateWrapper = styled.div`
  text-align: center;
  margin-bottom: 30px;
`
const BackLink = styled(Link)`
  background-color: #C3333B;
  padding: 10px 36px;
  border-radius: 40px;
  color: white;
  font-weight: bold;
  display: block;
  width: 80%;
  max-width: 100px;
  margin: 0 auto;
`

export const BrowsePage = ({ locations, cart, setCart }) => {
  const params = useParams();
  useTitle(`Browse City - ${!!params.zipcode ? params.zipcode : "All"}`);
  if ((params.zipcode && params.zipcode !== "76448") || !Array.isArray(locations)) {
    return (
      <EmptyStateWrapper>
        <ClosedSignWrapper>
          <ClosedSign />
        </ClosedSignWrapper>
        <h1>There are no stores in this location.</h1>
        <BackLink to="/browse" alt="Go home">Go Back</BackLink>
        <br />
        <hr />
        <div class="elfsight-app-b2915136-235e-4177-bf32-c00dbcb17704"></div>
      </EmptyStateWrapper>
    );
  }
  return (
    <>
      {locations.map((l) => (
        <LocationRow key={l.id} location={l} cart={cart} setCart={setCart} />
      ))}
      <br/>
    </>
    );
};

export default BrowsePage;
