import * as React from "react"

function Square(props) {
  return (
    <svg
      width={36}
      height={36}
      viewBox="1 0.901 53.201 53.199"
      {...props}
    >
      <path d="M45.333.901H9.868C4.992.901 1 4.891 1 9.769v35.466C1 50.109 4.992 54.1 9.868 54.1h35.466c4.876 0 8.868-3.99 8.868-8.865V9.769C54.201 4.891 50.209.901 45.333.901m-.806 40.708a2.825 2.825 0 01-2.82 2.815H13.492a2.826 2.826 0 01-2.82-2.815V13.395a2.83 2.83 0 012.82-2.82h28.215c1.551 0 2.82 1.27 2.82 2.82v28.214zm-9.67-8.466c0 .889-.726 1.612-1.61 1.612H21.962c-.887 0-1.61-.724-1.61-1.612V21.859c0-.887.723-1.611 1.61-1.611h11.284c.885 0 1.61.725 1.61 1.611v11.284z" />
    </svg>
  )
}

export default Square