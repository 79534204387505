import { useState, useEffect } from 'react';

/**
 * This will add the square SDK to the page in a way that React can track
 */
export const useSquare = () => {
    const [squareStatus, setSquareStatus] = useState(null);
    useEffect(() => {
        const sqPaymentScript = document.createElement("script");
        // sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
        const appId = process.env.REACT_APP_SQ_APP_ID;
        sqPaymentScript.src = appId.startsWith('sandbox') ? "https://js.squareupsandbox.com/v2/paymentform" : "https://js.squareup.com/v2/paymentform";

        // defaultClient.basePath = 'https://connect.squareupsandbox.com';
        sqPaymentScript.crossorigin = "anonymous";
        sqPaymentScript.onload = () => setSquareStatus("SUCCESS");
        sqPaymentScript.onerror = () => setSquareStatus("ERROR");
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    }, []) // on mount, add the js script dynamically
    return squareStatus;
};

export default useSquare;