import React, { Component, useRef, useState, useEffect } from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import './square.css'

const Input = styled.input`
  color: white;
`

const Flex1 = styled.div`
flex: 1;
`
const Flex = styled.div`
display: flex;
width: calc(100% - 8px);
`
const styles = {
    name: {
        verticalAlign: "top",
        display: "none",
        margin: 4,
        border: "none",
        fontSize: "16px",
        fontFamily: "Helvetica Neue",
        padding: "16px",
        color: "black",
        // backgroundColor: "transparent",
        lineHeight: "1.15em",
        placeholderColor: "#000",
        _webkitFontSmoothing: "antialiased",
        _mozOsxFontSmoothing: "grayscale"
    },
    leftCenter: {
        float: "left",
        textAlign: "center"
    },
    blockRight: {
        display: "block",
        float: "right"
    },
    center: {
        textAlign: "center"
    }
};

export class SquarePaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardBrand: "",
            nonces: [],
            googlePay: false,
            applePay: false,
            masterpass: false,
            nameOnCard: ""
        };
        this.requestCardNonce = this.requestCardNonce.bind(this);
    }

    requestCardNonce() {
        this.paymentForm.requestCardNonce();
    }

    componentDidMount() {
        const config = {
            applicationId: process.env.REACT_APP_SQ_APP_ID,
            locationId: "GMT96A77XABR1",
            inputClass: "sq-input",
            autoBuild: false,
            inputStyles: [
                {
                    fontSize: "16px",
                    fontFamily: "Helvetica Neue",
                    padding: "16px",
                    color: "black",
                    backgroundColor: "transparent",
                    lineHeight: "1.15em",
                    placeholderColor: "#000",
                    _webkitFontSmoothing: "antialiased",
                    _mozOsxFontSmoothing: "grayscale"
                }
            ],
            applePay: {
                elementId: "sq-apple-pay"
            },
            masterpass: {
                elementId: "sq-masterpass"
            },
            googlePay: {
                elementId: "sq-google-pay"
            },
            cardNumber: {
                elementId: "sq-card-number",
                placeholder: "• • • •  • • • •  • • • •  • • • •"
            },
            cvv: {
                elementId: "sq-cvv",
                placeholder: "CVV"
            },
            expirationDate: {
                elementId: "sq-expiration-date",
                placeholder: "MM/YY"
            },
            postalCode: {
                elementId: "sq-postal-code",
                placeholder: "Zip"
            },
            callbacks: {
                methodsSupported: methods => {
                    if (methods.googlePay) {
                        this.setState({
                            googlePay: methods.googlePay
                        });
                    }
                    if (methods.applePay) {
                        this.setState({
                            applePay: methods.applePay
                        });
                    }
                    if (methods.masterpass) {
                        this.setState({
                            masterpass: methods.masterpass
                        });
                    }
                    return;
                },
                createPaymentRequest: () => {
                    return {
                        requestShippingAddress: false,
                        requestBillingInfo: true,
                        currencyCode: "USD",
                        countryCode: "US",
                        total: {
                            label: "MERCHANT NAME",
                            amount: "100",
                            pending: false
                        },
                        lineItems: [
                            {
                                label: "Subtotal",
                                amount: "100",
                                pending: false
                            }
                        ]
                    };
                },
                cardNonceResponseReceived: async (errors, nonce, cardData) => {
                    if (errors) {
                        // Log errors from nonce generation to the Javascript console
                        console.log("Encountered errors:");
                        errors.forEach(function (error) {
                            console.log("  " + error.message);
                        });

                        return;
                    }
                    this.setState(state => ({
                        nonces: [...state.nonces, nonce]
                    }));
                    if (this.props.orderCount !== this.state.nonces.length) {
                        this.requestCardNonce();
                    } else {
                        let headers = {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                        if (this.props.auth0.isAuthenticated) {
                            const token = await this.props.auth0.getAccessTokenSilently({
                                audience: process.env.REACT_APP_BAM_API_URL,
                                scope: 'openid profile email',
                            });
                            headers = { ...headers, Authorization: `Bearer ${token}` };                        
                        }
                        
                        fetch('/api/checkout', {
                            method: 'POST',
                            headers,
                            body: JSON.stringify({
                              nonces: this.state.nonces,
                              cart: this.props.cart,
                              name: this.props.user?.displayName || this.state.nameOnCard,
                              phoneNumber: this.props.phoneNumber,
                              pickupDates: this.props.pickupDate,
                              pickupDateText: this.props.pickupDateText,
                              fullfilmentType: this.props.fullfilmentType,
                              shippingAddress: this.props.shippingAddress,
                              fname: this.props.fname,
                              lname: this.props.lname,
                              phone: this.props.phone,
                              email: this.props.email,
                            })
                          })
                          .catch(err => {
                            alert('Network error: ' + err);
                          })
                          .then(response => {
                            if (!response.ok) {
                              return response.json().then(errorInfo => Promise.reject(errorInfo));
                            }
                            return response.json();
                          })
                          .then(data => {
                            this.props.onPaymentDone(data.result);
                          })
                          .catch(err => {
                            console.error(err);
                            alert('Payment failed to complete!\nCheck browser developer console for more details');
                          });
                    }
                },
                unsupportedBrowserDetected: () => { },
                inputEventReceived: inputEvent => {
                    switch (inputEvent.eventType) {
                        case "focusClassAdded":
                            break;
                        case "focusClassRemoved":
                            break;
                        case "errorClassAdded":
                            document.getElementById("error").innerHTML =
                                "Please fix card information errors before continuing.";
                            break;
                        case "errorClassRemoved":
                            document.getElementById("error").style.display = "none";
                            break;
                        case "cardBrandChanged":
                            if (inputEvent.cardBrand !== "unknown") {
                                this.setState({
                                    cardBrand: inputEvent.cardBrand
                                });
                            } else {
                                this.setState({
                                    cardBrand: ""
                                });
                            }
                            break;
                        case "postalCodeChanged":
                            break;
                        default:
                            break;
                    }
                },
                paymentFormLoaded: function () {
                    document.getElementById("name").style.display = "inline-flex";
                }
            }
        };
        this.paymentForm = new this.props.paymentForm(config);
        this.paymentForm.build();
    }

    render() {
        return (
            <div className="container">
                <div id="form-container">
                    <div id="sq-ccbox">
                        <p>
                            <span style={styles.leftCenter}>Enter Card Info Below </span>
                            <span style={styles.blockRight}>
                                {this.state.cardBrand.toUpperCase()}
                            </span>
                        </p>
                        <div id="cc-field-wrapper">
                            <Input
                                onChange={e => this.setState({ nameOnCard: e.currentTarget.value })}
                                id="name"
                                style={styles.name}
                                type="text"
                                placeholder="Name"
                            />

                            <div id="sq-card-number" />
                            <Flex>
                                <Flex1>
                                    <div id="sq-expiration-date" />
                                </Flex1>
                                <Flex1>
                                    <div id="sq-cvv" />
                                </Flex1>
                                <Flex1>
                                    <div id="sq-postal-code" />
                                </Flex1>
                            </Flex>
                            <input type="hidden" id="card-nonce" name="nonce" />
                        </div>
                    </div>
                    <button
                        className="button-credit-card"
                        onClick={this.requestCardNonce}
                    >
                        Pay
            </button>
                </div>
                <p style={styles.center} id="error" />
            </div>
        );
    }
}

export default withAuth0(SquarePaymentForm)