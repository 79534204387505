import React, { useState } from "react";
import styled from 'styled-components';
import Dialog from "components/Dialog";
import {Flex} from "components/Flex";
import useTitle from "hooks/useTitle";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from 'react-router-dom';
import TextField from "components/TextField";

const DangerZoneSection = styled.section`
  margin-bottom: 16px;
  border: 1px solid #e5534b66;
  padding: 10px;
  border-radius: 6px;
`
const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;
`
const Button = styled.button`
  background-color: #fff;
  appearance: none;
  border: 1px solid #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 5px 16px;
  position: relative;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: inset 0 1px 0 rgba(225, 228, 232, 0.2);
  border-color: 1px solid rgba(27, 31, 35, 0.15);
  font-weight: 600;
  &:hover {
    background-color: #f7f7f7;
  }
`;
const DeleteButton = styled(Button)`
  position: absolute;
  right: 10px;
  padding: 6px;
  margin: 6px;
`
const ConfirmDeleteButton = styled(Button)`
  color: #d73a49;
  :disabled {
    color: rgba(215, 58, 73, 0.5);
  }
`
const UpdateButton = styled(Button)`
  background-color: #4CAF50;
  color: white;

  &:hover {
    background-color: rgb(44, 151, 75);
  }
  &:disabled {
    background-color: rgb(148, 211, 162);
    border-color: rgba(27, 31, 35, 0.1);
    color: rgba(255, 255, 255, 0.8);
  }
`
const LinkButton = styled.button`
  color: #0076ac;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: .8em;
  margin: 0;
  padding: 0;
`

const EditProfileSection = ({ }) => {
  const { getAccessTokenSilently, user, logout, loginWithRedirect } = useAuth0();
  const [firstName, setFirstName] = useState(user.given_name);
  const [lastName, setLastName] = useState(user.family_name);

  const didChange = firstName !== user.given_name || lastName !== user.family_name;

  const updateAccount = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_BAM_API_URL,
      scope: 'openid profile email',
    });
    const res = await fetch("/api/account", {
      method: "PATCH",
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}` 
      },
      body: JSON.stringify({ firstName, lastName })
    });
    const body = await res.json();
    // TODO we should store the user info someplace other than useAuth so we can update it. 
    loginWithRedirect();
  }

  switch (user.sub.split('|')[0]) {
    case "auth0": 
      return (
        <>
          <h1>Edit Profile&nbsp;(<LinkButton className="logout" onClick={logout}>Logout</LinkButton>)</h1>
          <TextField name="First Name" value={firstName} onChange={e => setFirstName(e.currentTarget.value)} />
          <TextField name="Last Name" value={lastName} onChange={e => setLastName(e.currentTarget.value)} />
          {didChange && <small>You will need to log back in after saving.<br /></small>}<br />
          <UpdateButton onClick={updateAccount} disabled={!didChange}>Save Changes</UpdateButton> 
        </>
      );
    case "facebook":
      return (
        <>
          <h1>Welcome {user.given_name} {user.family_name} &nbsp;(<LinkButton className="logout" onClick={logout}>Logout</LinkButton>)</h1>
          <p>Your account is managed by facebook. Please use facebook to modify profile data.</p>
          <hr />
        </>
      );
    case "google-oauth2":
      return (
        <>
          <h1>Welcome {user.given_name} {user.family_name} &nbsp;(<LinkButton className="logout" onClick={logout}>Logout</LinkButton>)</h1>
          <p>Your account is managed by google. Please use google to modify profile data.</p>
          <hr />
        </>
      );
  }
};

const DangerZone = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");
  const { getAccessTokenSilently, logout } = useAuth0();

  const deleteAccount = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_BAM_API_URL,
      scope: 'openid profile email',
    });
    const res = await fetch("/api/account", {
      method: "DELETE",
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}` 
      },
    });
    const body = await res.json();
    if (body.success) {
      logout();
    }
  };

  return (
    <>
      <h2>Danger Zone</h2>
      <DangerZoneSection>
        <Flex>
          <div>
            <strong>Delete BAM Account</strong>
            <div style={{ marginTop: 4 }}>Once you delete your account you will not be able to access you user data anymore.</div>
          </div>
          <DeleteButton className="delete" onClick={() => setIsDialogOpen(true)}>
            Delete Account
          </DeleteButton>
        </Flex>
        {isDialogOpen && (
          <Dialog close={() => setIsDialogOpen(false)}>
            <div style={{ padding: "10px 32px 30px 32px" }}>
              <h1>DANGER!</h1>
              <b>
                This action cannot be undone.
              </b>
              <p>
                You are about to permanently delete your BAM account and all data in it.
              </p>
              <p>
                Please type <b>DELETE</b> into the textbox to confirm.
              </p>
              <TextField onChange={(e) => setDeleteConfirmationText(e.currentTarget.value)} /> &nbsp;
              <ConfirmDeleteButton
                disabled={deleteConfirmationText.toUpperCase() !== "DELETE"}
                onClick={deleteAccount}
              >
                Confirm Delete
              </ConfirmDeleteButton>
            </div>
          </Dialog>
        )}
      </DangerZoneSection>
    </>
  );
}

export default () => {
  useTitle("Edit Profile");
  const { isAuthenticated } = useAuth0();
  // If the user is not authenticated then they cannot view this page
  if (!isAuthenticated) {
    return <Redirect to="/browse" />
  }
  return (
    <Wrapper>
      <EditProfileSection />
      <DangerZone />
    </Wrapper>
  );
};