import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import useCreatePortal from '../hooks/useCreatePortal';
import useClickOutside from '../hooks/useClickOutside';
import { TOOLBAR_HEIGHT } from '../constants'
import { useHistory } from "react-router-dom";
import Trash from 'icons/Trash';
import { generateCartCountRange } from "../util";
import { Flex, Spacer } from './Flex';
import NavigateNext from 'icons/NavigateNext';

const CartDialog = styled.div`
  position: fixed; 
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  background: white;
  width: 100%;
  max-width: 480px;
  border-bottom-left-radius: 4px;
  box-shadow: ${props => props.isDesktop ? "0" : "0 0 2px 0 rgba(27,33,38,.16), 0 2px 2px 0 rgba(27,33,38,.32)"};
  border-left: ${props => props.isDesktop ? "1px solid #CFD8DC" : "0"};
  min-height: 300px;
  height: 100%;
  z-index: 100;
  transition: 0.5s;
  transform: ${props => {
    if (props.isMobile) {
      return props.isOpen ? 'translateY(0)' : 'translateY(100%)'
    }
    return props.isOpen ? 'translateX(0)' : 'translateX(100%)'
  }};
`

const LocationName = styled.p`
  margin: 0;
  display: block;
  flex: 1;
  font-size: 22px;
  line-height: 42px;
  padding-left: 8px;
`
const LocationPrice = styled.p`
  margin: 0;
  display: block;
  line-height: 42px;
  font-weight: bold;
  margin-right: 8px;
`

const ItemName = styled.p`
  padding-left: 14px;
  margin: 0;
  margin-top: 10px;
  font-size: 18px;
  display: block;
  line-height: 22px;
`
const ItemUnitPrice = styled.p`
  margin: 0;
  display: block;
  line-height: 22px;
  padding-left: 14px;
  color: rgba(0, 0, 0, .7);
`
const ItemTotalPrice = styled.p`
  margin: 0;
  display: block;
  font-weight: bold;
  min-width: 80px;
  text-align: right;
  margin-right: 8px;
  line-height: 64px;
`
const CartToolbar = styled.div`
  height: ${TOOLBAR_HEIGHT}px;
  top: 0;
  display: flex;
`
const ToolbarPadding = styled.div`
  width: 120px;
`
const CloseButton = styled.button`
  width: 120px;
  background: none;
  border: none;
  outline: none;
`
const CartBody = styled.div`
  flex: 1;
  overflow-y: auto;
`

const CartTitle = styled.p`
  flex: 1;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`
const CheckoutButtonWrapper = styled.div`
  height: ${props => props.hasDiscount ? "190px": "160px"};
  background: #F6F6F6;
  position: relative;
`
const CheckoutButton = styled.button`
  display: block;
  height: 64px;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 10px;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  padding-left: 20px;
  letter-spacing: 0.2px;
  width: calc(100% - 20px);
  position: absolute;
  bottom: 10px;
  left: 10px;

`

const OrderSeparator = styled.hr`
  margin: 0;
  height: 10px;
  background-color: #ECEFF1;
  border: 0;
  border-top: 1px solid #CFD8DC;
  border-bottom: 1px solid #CFD8DC;
`

const OrderHeader = styled.div`
  min-height: 42px;
  display: flex;
  border-bottom: 1px solid #CFD8DC;
`
const OrderItem = styled.div`
  display: flex;
  line-height: 42px;
  min-height: 42px;
  margin-top: 4px;
  margin-bottom: 4px;
`

const NumberInput = styled.input`
  margin: 14px;
  font-size: 18px;
  width: 68px;
  padding-left: 8px;
`
const OrderItemMain = styled.div`
  flex: 1;
`
const ItemRemove = styled.div`
  color: #616161;
  line-height: 16px;
  position: relative;
  top: -6px;
  svg {
    fill: rgb(244 67 54 / .9);
    margin-left: 10px;
    position: relative;
    top: 6px;
  }
`

const Select = styled.select`
  min-width: 64px;
  height: 42px;
  margin-top: 10px;
`

const ItemModifiers = styled.ul`
  padding-left: 34px;
  margin: 0;
  color: rgba(0, 0, 0, .7);
  line-height: 18px;
`

export const CartPreview = ({ cart=[], setCart, user, setIsOpen, isOpen, openButtonRef, isMobile, isDesktop }) => {
  const [orders, setOrders] = useState([])
  const createBodyPortal = useCreatePortal();
  const ref = useRef();
  useClickOutside(ref, () => setIsOpen(false), openButtonRef);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BAM_API_URL}/api/checkout/calculate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cart,
        name: user?.displayName || "test",
      }),
    })
      .then(async res => { 
        if (res.status === 500) {
          setCart({});
          return [];
        }
        return await res.json()
      })
      .then(orders => {
        if (orders.length > 0) {
          setOrders(orders)
        }
      });
  }, [user, cart]);
  const history = useHistory();
  const close = () => setIsOpen(false);
  const goToCheckout = () => {
    history.push("/checkout");
    close();
  };
  if (!Array.isArray(orders)) {
    return "Loading...";
  }
  
  const numberOfCartItems = Object.values(cart).filter(x => x).reduce((acc, item) => acc + Object.values(item).filter(x => x).length, 0)
  const orderTotal = numberOfCartItems ? (orders.reduce((acc, order) => acc + order.totalMoney.amount, 0)/100).toFixed(2) : "0.00";
  const orderTaxes = numberOfCartItems ? (orders.reduce((acc, order) => acc + order.totalTaxMoney.amount + order.totalServiceChargeMoney.amount, 0)/100).toFixed(2) : "0.00";
  const orderDiscount = numberOfCartItems ? (orders.reduce((acc, order) => acc + order.totalDiscountMoney.amount, 0)/100).toFixed(2) : "0.00";
  const hasDiscount = Number.parseFloat(orderDiscount) > 0;

  return createBodyPortal(
    <CartDialog isMobile={isMobile} isDesktop={isDesktop} isOpen={isOpen} ref={ref}>
      <CartToolbar>
        {isMobile && !isDesktop ? <CloseButton onClick={close}>Close</CloseButton> : <ToolbarPadding />}
        <CartTitle>Cart</CartTitle>
        {isMobile || isDesktop ? <ToolbarPadding /> : <CloseButton onClick={close}>Close</CloseButton>}
      </CartToolbar>
      <CartBody>
        {numberOfCartItems === 0 ? "" : orders.map((order, i) => (
          <>
            <OrderSeparator key={i} />
            <div key={order.catalogObjectId}>
              <OrderHeader>
                <LocationName>{order.locationName}</LocationName>
                <LocationPrice>${(order.totalMoney.amount/100).toFixed(2)}</LocationPrice>
              </OrderHeader>
              {order.lineItems?.map(item => {
                const isSoldByWeight = item.quantityUnit?.measurementUnit?.weightUnit === "IMPERIAL_POUND";
                const step = Number.parseFloat(item.customAttributeValues.step) || 1;
                const minAmount = Number.parseFloat(item.customAttributeValues.min_amount) || 1;
                const maxAmount = Number.parseFloat(item.customAttributeValues.max_amount) || 9;  
                const key = item.modifiers
                  ? [item.catalogObjectId, ...item.modifiers.map(m => m.catalogObjectId)].join('-')
                  : item.catalogObjectId; 
                return (
                  <OrderItem key={item.catalogObjectId}>
                    {/* <img src={item.src} /> */}
                    <OrderItemMain>
                      <ItemName>{item.name}</ItemName>
                      <ItemUnitPrice>{item.variationName} - ${(item.basePriceMoney.amount/100).toFixed(2)} {isSoldByWeight ? "/ lb" : "Each"}</ItemUnitPrice>
                      <ItemModifiers>
                      {item.modifiers?.map(m => <li>{m.name} - ${(m.basePriceMoney?.amount/100).toFixed(2)}</li>)}
                      </ItemModifiers>
                      <ItemRemove onClick={e => {
                        setIsOpen(true);
                        if (order.lineItems.length === 1) {
                          delete orders[i]
                          setOrders(orders.filter(x => !!x))
                          setCart({ ...cart, [order.locationId]: undefined })
                        } else {
                          setOrders([
                            ...orders.slice(0, i), 
                            {
                              ...order,
                              lineItems: order.lineItems.filter(lineItem => { 
                                const idMatch = lineItem.catalogObjectId === item.catalogObjectId;
                                if (item.modifiers) {
                                  const modifiersMatch = lineItem.modifiers === item.modifiers;
                                  return !(idMatch && modifiersMatch)
                                }
                                return !idMatch
                              })
                            }, 
                            ...orders.slice(i+1)
                          ])
                          setCart({
                            ...cart,
                            [order.locationId]: {
                              ...cart[order.locationId],
                              [key]: undefined
                            }
                          })
                        }
                      }}><Trash />Delete</ItemRemove>
                    </OrderItemMain>

                    <Select value={Number.parseFloat(item.quantity).toFixed(2)} onChange={e => {
                      const newValue = Number.parseFloat(e.currentTarget.value);
                      if (newValue === 0) {
                        if (order.line_items.length === 1) {
                          delete orders[i]
                          setOrders(orders.filter(x => !!x))
                          setCart({ ...cart, [order.locationId]: undefined })
                        } else {
                          setOrders([
                            ...orders.slice(0, i), 
                            {
                              ...order,
                              lineItems: order.lineItems.filter(lineItem => { 
                                const idMatch = lineItem.catalogObjectId === item.catalogObjectId;
                                if (item.modifiers) {
                                  const modifiersMatch = lineItem.modifiers === item.modifiers;
                                  return !(idMatch && modifiersMatch)
                                }
                                return !idMatch
                              })
                            }, 
                            ...orders.slice(i+1)
                          ])
                          setCart({
                            ...cart,
                            [order.locationId]: {
                              ...cart[order.locationId],
                              [key]: undefined
                            }
                          })
                        }
                      } else {
                        setCart({...cart, [order.locationId]: { ...cart[order.locationId], [key]: newValue }})
                      }
                    }}>
                      {generateCartCountRange(step, minAmount, maxAmount).map(i => 
                        <option value={Number.parseFloat(i).toFixed(2)}>{i}</option>
                      )}
                    </Select>
                    {/* <ItemTotalPrice>${(item.grossSalesMoney.amount/100).toFixed(2)}</ItemTotalPrice> */}
                    <ItemTotalPrice>${(item.basePriceMoney.amount/100).toFixed(2)}</ItemTotalPrice>
                  </OrderItem>
                )
              })}
            </div>
          </>
        ))}
        
      </CartBody>
      <CheckoutButtonWrapper hasDiscount={hasDiscount}>
        <Flex style={{ margin: 14 }}>
          <div>Taxes &amp; Fees</div>
          <Spacer />
          <b>${orderTaxes}</b>
        </Flex>
        {hasDiscount && <Flex style={{ margin: 14 }}>
          <div>Discount</div>
          <Spacer />
          <b>${orderDiscount}</b>
        </Flex>}
        <Flex style={{ margin: 14 }}>
          <div>Total {orders.length} {orders.length === 1 ? "Store" : "Stores"}</div>
          <Spacer />
          <b>${orderTotal}</b>
        </Flex>
        <CheckoutButton onClick={goToCheckout} className="goToCheckout">
          <Flex><div>Proceed to Checkout</div><Spacer /><NavigateNext fill="white" /></Flex>
        </CheckoutButton>
      </CheckoutButtonWrapper>
    </CartDialog>
  );
}

export default CartPreview;