import { createSlice } from '@reduxjs/toolkit';

const NAMESPACE = 'cart';

const cachedState = localStorage.getItem(NAMESPACE);
const initialState = cachedState ? JSON.parse(cachedState) : {};

export const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    addToCart: state => {
      state.value = 1;
    },
    increment: state => {
      state.value -= 1;
    },
    decrement: (state, action) => {
      state.value += action.payload;
    },
  },
});

export const { increment, decrement, addToCart } = slice.actions;

// // The function below is called a selector and allows us to select a value from
// // the state. Selectors can also be defined inline where they're used instead of
// // in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default slice.reducer;
