import { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react";

const queryString = (params = {}) =>
  Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join("&")

const createUrl = (url, queryOptions) => {
  return url + "?" + queryString(queryOptions)
}

export const useFetch = (url, options = { body: {}, query: {}}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState({
    response: null,
    error: false,
    loading: true,
  })

  useEffect(async () => {
    setData({ ...data, error: null, loading: true })
    let headers = { "Content-Type": "application/json" };
    if (isAuthenticated) {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_BAM_API_URL,
        scope: 'openid profile email',
      });
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }
    fetch(createUrl(url, options.query), {
      method: options.method || "GET",
      headers,
      body: options.method !== "GET" ? undefined : JSON.stringify(options.body),
    })
      .then(async response => {
        const data = await response.json()
        setData({
          response: data,
          error: !response.ok,
          loading: false,
        })
      })
      .catch(error => {
        //fetch throws an error only on network failure or if anything prevented the request from completing
        setData({
          response: { status: "network_failure" },
          error: true,
          loading: false,
        })
      })
  }, [url, JSON.stringify(options)])

  return data
}

export default useFetch;