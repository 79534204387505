import React, { useState } from "react";
import styled from "styled-components";
import {
  SearchBox,
  InfiniteHits,
  ClearRefinements,
  RefinementList,
  Configure,
  PoweredBy,
} from "react-instantsearch-dom";
import { ResponsiveFlex, Flex } from "components/Flex"; 
import { ItemCard } from "components/ProductGrid";
import useCreatePortal from "hooks/useCreatePortal";

const StyledSearchBox = styled(SearchBox)`
  width: 100%;
  margin-top: 9px;
  input {
    font-weight: 600;
    font-size: 18px;
    height: 36px;
  }
`;
export const SideBar = styled.div`
  min-width: 240px;
  margin-right: 20px;
  @media (max-width: 776px) {
    display: none;
  }
`;

export const MobileAdvancedSearch = styled.div`
  display: none;

  @media (max-width: 776px) {
    display: block;
    button {
    }
    main {
      border: 1px solid #c1c1c1;
      transition: all 0.3s;
      height: 360px;
      overflow: hidden;
    }
    main.closed {
      height: 0px;
      border: 0;
    }
  }
`;

const SearchContent = styled.div`
  width: 100%;
  .ais-InfiniteHits {
    width: 100%;
  }
`;

const FullBleedSearchResults = styled.div`
  position: absolute;
  top: 142px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: white;
  z-index: 10;
  width: 100%;

  @media (min-width: 1440px) {
    width: calc(100% - 480px);
  }
`;

export const SearchResults = ({ cart, setCart }) => {
  const [isMobileAdvancedSearchOpen, setIsMobileAdvancedSearchOpen] = useState(
    false
  );
  const createBodyPortal = useCreatePortal();

  return createBodyPortal(
    <FullBleedSearchResults>
      <ResponsiveFlex>
        <SideBar>
          <ClearRefinements />
          <h2>Stores</h2>
          <RefinementList searchable attribute="store" />
          <h2>Cities</h2>
          <RefinementList searchable attribute="city" />
          <h2>Categories</h2>
          <RefinementList searchable attribute="category" />
          <PoweredBy />
        </SideBar>
        <SearchContent>
          <br />
          <MobileAdvancedSearch>
            <button
              onClick={() =>
                setIsMobileAdvancedSearchOpen(!isMobileAdvancedSearchOpen)
              }
            >
              Advanced Search
            </button>
            <main className={isMobileAdvancedSearchOpen ? "" : "closed"}>
              <ClearRefinements />
              <h2>Stores</h2>
              <RefinementList searchable attribute="store" />
              <h2>Cities</h2>
              <RefinementList searchable attribute="city" />
              <h2>Categories</h2>
              <RefinementList searchable attribute="category" />
              <PoweredBy />
            </main>
          </MobileAdvancedSearch>
          <br />
          <br />
          <Flex>
            <Configure hitsPerPage={10} />
            <InfiniteHits
              hitComponent={({ hit }) => {
                const count = Number.parseFloat(
                  (cart[hit.locationId] &&
                    cart[hit.locationId] &&
                    cart[hit.locationId][hit.variationId]) ||
                    0
                );
                return (
                  <ItemCard
                    {...hit}
                    count={count}
                    setCart={setCart}
                    cart={cart}
                  />
                );
              }}
            />
          </Flex>
          <br />
        </SearchContent>
      </ResponsiveFlex>
    </FullBleedSearchResults>
  );
};
const DEBOUNCE_TIME = 400;
export const Search = ({ cart, setCart }) => {
  return (
    <>
      <StyledSearchBox />
      {/* {searchState.query && <SearchResults cart={cart} setCart={setCart} />} */}
    </>
  );
};

export default SearchResults;
