import * as React from "react"

function ClosedSign(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91 91" {...props}>
      <path d="M5.098 36.71c.299.01.598.018.896.029-.77 5.072-.621 10.483-.875 15.485-.35 6.814-.463 13.662-.521 20.486-.012 1.479 1.281 2.797 2.773 2.771 13.852-.234 27.709-.264 41.563-.352 6.73-.043 13.461-.076 20.189-.184 4.857-.08 10.848.672 13.951-3.936 2.955-4.383 3.023-11.225 3.416-16.311.473-6.105.746-12.721-.41-18.776-.359-1.873-1.295-3.191-3.283-3.572-2.42-.461-5.209-.221-7.824-.068a2.91 2.91 0 00-.932-1.068c-8.793-6.256-17.635-12.52-25.275-20.188-.906-.91-2.555-.967-3.455 0-4.207 4.523-8.811 8.77-13.801 12.42a127.046 127.046 0 01-6.916 4.715c-1.617 1.027-3.287 2.195-4.998 3.246-4.834-.211-9.668-.441-14.498-.719-3.858-.218-3.879 5.881 0 6.022zm28.631-9.467c4.902-3.15 9.295-6.881 13.361-11.016 6.023 5.859 12.545 11.092 19.225 16.164-4.873.01-9.746-.004-14.617-.064-8.74-.109-17.477-.346-26.209-.678 2.857-1.277 5.601-2.711 8.24-4.406zm44.261 9.563c.658-.023 3.314-.453 3.674-.225.42.266.387 2.521.451 3.328.248 3.028.344 6.069.283 9.106-.111 5.574-.592 11.447-2.162 16.824-.795 2.723-2.191 3.975-5.072 4.086-2.992.113-6.016-.023-9.01-.043-5.939-.041-11.877.006-17.813.023-12.709.037-25.416.117-38.125.045.16-5.906.266-11.824.189-17.727-.064-4.961.277-10.299-.283-15.354 22.618.71 45.253.706 67.868-.063z" />
      <path d="M18.314 47.57c2.949 1.061 5.441 2.271 7.826 4.113-2.852 2.453-5.832 4.959-7.891 8.109-.439.676.42 1.461 1.078 1.078 3.348-1.947 6.357-4.51 9.475-6.824 2.107 2.059 4.027 4.295 6.027 6.459 2.061 2.232 5.498-1.182 3.34-3.342-1.732-1.734-3.656-3.713-5.744-5.566 2.26-1.451 4.594-3.02 5.936-5.045.883-1.334-.6-3.391-2.16-2.811-2.553.951-4.887 3.141-7.051 5.197-3.016-2.191-6.309-3.828-9.822-4.018-.518-.029-1 .301-1.262.725-.109.172-.217.346-.324.52-.347.56.012 1.204.572 1.405zM43.527 51.922c4.555.898 9.729-.066 14.334-.375 5.072-.338 10.156-.602 15.221-1.039 3.158-.244 3.117-5.248 0-4.912-5.082.547-10.15 1.27-15.221 1.914-4.584.584-10.047.607-14.334 2.352-.867.353-1.152 1.833 0 2.06zM56.721 54.018c-2.205.623-4.521.887-6.76 1.373-1.824.398-3.768.557-5.201 1.809-.5.438-.17 1.168.361 1.363 1.662.611 3.527.113 5.271.018 2.041-.111 4.297-.344 6.328-.061 4.05.568 3.466-5.481.001-4.502z" />
    </svg>
  )
}

export default ClosedSign
