import React from "react";
import useFetch from "hooks/useFetch";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Flex, Flex1 } from "components/Flex";
import useTitle from "hooks/useTitle";

const Card = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 90px;
  box-shadow: 0 4px 4px 0
    ${props => props.theme.colors.transparentBlack};
  margin: 20px;
  padding: 10px;
`
const OrderDetails = styled.div``
const ItemsPreview = styled.div``

const OrderHistoryDetailsPage = () => {
  useTitle("Order History Details");
  let query = useParams();
  const historyRequest = useFetch(
    `${process.env.REACT_APP_BAM_API_URL}/api/history/${query.merchantId}`
  );
  return (
    <div className="order-history-page">
      <h1>Order History</h1>
      {historyRequest.loading && "Loading"}
      {historyRequest.error  && "Error Please Refresh the page"}
      {Array.isArray(historyRequest.response) && historyRequest.response.map(order => (
        <Card className="previousOrder">
          <OrderDetails>
            <Flex>
              <Flex1>Order Placed</Flex1>
              <Flex1>Total</Flex1>
              <Flex1>Order #</Flex1>
              <Flex1>{order.fulfillments[0]?.state === "COMPLETED" ? "Picked Up" : "Pick Up"}</Flex1>
            </Flex>
            <hr />
            <Flex>
              <Flex1>{order.createdAt}</Flex1>
              <Flex1>{(order.totalMoney.amount/100).toFixed(2)}</Flex1>
              <Flex1>{order.id}</Flex1>
              <Flex1>{order.fulfillments[0]?.pickupDetails?.pickupAt}</Flex1>
            </Flex>
          </OrderDetails>
          <ItemsPreview></ItemsPreview>
        </Card>
      ))}
    </div>
  );
};

export default OrderHistoryDetailsPage;
