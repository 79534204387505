import React from 'react';
import styled from 'styled-components'
import useTitle from "hooks/useTitle";

const Iframe = styled.iframe`
  margin-left: 50%;
  max-width: 830px;
  width: 100%;
  height: calc(100vh - 52px);
  border: none;
  transform: translateX(-50%);
`

export default () => {
  useTitle("Privacy Policy");
  return (
    <Iframe src="https://docs.google.com/document/d/e/2PACX-1vRAIudYqxNlagi9Goi1CT1baQ5wVFWB8ootTprt1UjYziBzomE4pczPxR-2CFofxg/pub?embedded=true" />
  )
}