import React from 'react';
import styled from 'styled-components';
import SquareIcon from 'icons/Square';
import useTitle from "hooks/useTitle";

const Card = styled.div`
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  font-size: 24px;
  background: white;
  width: 480px;
  margin: 40px auto;
  padding-bottom: 30px;
`
const SignInWithSquareWrapper = styled.a`
    display: block;
    background: #212121;
    color: white;
    text-decoration: none;
    border: none;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: .25px;
    overflow: hidden;
    text-align: center;
    text-overflow: clip;
    white-space: nowrap;
    font-weight: bold;
    padding-top: 1px;
    margin: 0 24px 0 12px;
    padding: 20px 30px 20px 60px;
    line-height: 44px;
    border-radius: 4px;
    position: relative;
`;

const PaddedSquareIcon = styled(SquareIcon)`
    position: absolute;
    left: 24px;
    top: 24px;
    width: 24px;
`

const Padding20 = styled.div`
  padding: 20px;
`

const ALL_PERMISSIONS = [
    "ITEMS_WRITE",
    "ITEMS_READ",
    "ORDERS_WRITE",
    "PAYMENTS_WRITE",
    "PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS",
    "CUSTOMERS_WRITE",
    "CUSTOMERS_READ",
    "PAYMENTS_READ",
    "INVENTORY_WRITE",
    "INVENTORY_READ",
    "MERCHANT_PROFILE_READ",
    "LOYALTY_WRITE",
    "LOYALTY_READ",
    "ORDERS_READ",
    "PAYMENTS_WRITE_IN_PERSON",
];
const url = `${process.env.REACT_APP_SQ_BASE_URL}/oauth2/authorize?client_id=${process.env.REACT_APP_SQ_APP_ID}&scope=${ALL_PERMISSIONS.join("+")}`


export const MerchantPage = () => {
    useTitle("Become a Merchant");
    return (
        <Card>
            <Padding20>
                <SignInWithSquareWrapper href={url}><PaddedSquareIcon fill="white" />Sign in with Square</SignInWithSquareWrapper>
            </Padding20>
            <hr />
            <Padding20 style={{ padding: 20 }}>
                <div class="elfsight-app-b2915136-235e-4177-bf32-c00dbcb17704"></div>
            </Padding20>
        </Card>
    )
}

export default MerchantPage;
