import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'zipcode',
  initialState: null,
  reducers: {
    setZipcode: (state, action) => {
      state = action.payload;
    },
  },
});

export const { setZipcode } = slice.actions;

export const selectZipcode = state => state.zipcode;

export default slice.reducer;
