// @flow
import * as React from 'react';
import styled from 'styled-components';

const TriggerButton = styled.button`
    background: white;
    border: 0;
    width: 100%;
    height: 40px;
`
const CollapsibleWrapper = styled.div`
  border-radius: 4px;
  background-color: white;
  padding: 12px;
  margin: 8px;
  .collapsible-card-header {
      align-items: center;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;

      &.has-sticky-header {
          position: sticky;
          top: 0; 
          z-index: 1;
      }
  }

  .collapsible-card-action-items {
      bottom: 0;
      position: absolute;
      right: 34px;
  }

  .collapsible-card-header-caret {
      position: absolute;
      right: 0;
      transition: transform 200ms;
  }

  .btn-plain.collapsible-card-title {
      align-items: center;
      display: flex;
      padding: 10px 0;
      position: relative;
      text-align: left;
      width: 100%;
  }

  .collapsible-card-content {
      padding: 10px 0;
  }

  .collapsible-card-transition {
      overflow: hidden;
      transition-property: height;
      transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  &.is-open {
    .collapsible-card-transition {
        overflow: visible;
    }
  }

  &.is-open .collapsible-card-header-caret {
      transform: rotateZ(180deg);
  }

  &.is-bordered {
      border: 1px solid #eeeeee;

      .collapsible-card-header {
          background-color: $white;
      }

      .btn-plain.collapsible-card-title,
      .collapsible-card-content {
          padding: 10px;
      }

      &.is-open .btn-plain.collapsible-card-title {
          border-bottom: 1px solid #eeeeee;
      }

      .collapsible-card-header-caret {
          right: 10px;
      }
  }

  &:not(.is-bordered) {
      .btn-plain.collapsible-card-title {
          border-bottom: 1px solid #eeeeee;

          &:hover,
          &:active,
          &:focus {
              border-bottom-color: #0061d5;
              text-decoration: none;
          }
      }
  }

`;

class Collapsible extends React.PureComponent{
    transitionElement;

    static defaultProps = {
        buttonProps: {},
        className: '',
        isOpen: true,
        animationDuration: 100,
    };

    constructor(props) {
        super(props);
        this.transitionElement = React.createRef();
        this.state = {
            isOpen: props.isOpen,
            height: 'auto',
        };
    }

    componentDidMount() {
        const { current } = this.transitionElement;
        if (!current) {
            return;
        }
        this.setState(prevState => ({
            height: prevState.isOpen ? `${current.scrollHeight}px` : '0',
        }));
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (this.props.isOpen !== prevProps.isOpen) {
            return this.transitionElement.current.scrollHeight;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { current } = this.transitionElement;
        if (snapshot !== null && current !== null) {
            this.setState({
                isOpen: this.props.isOpen,
                height: this.props.isOpen ? `${current.scrollHeight}px` : '0',
            });
        }
    }

    toggleVisibility = () => {
        const { onOpen, onClose } = this.props;
        const { current } = this.transitionElement;
        if (!current) {
            return;
        }
        this.setState(
            prevState => ({
                isOpen: !prevState.isOpen,
                height: !prevState.isOpen ? `${current.scrollHeight}px` : '0',
            }),
            () => {
                const { isOpen } = this.state;
                if (isOpen && onOpen) {
                    onOpen(this);
                } else if (!isOpen && onClose) {
                    onClose(this);
                }
            },
        );
    };

    render() {
        const { isOpen, height } = this.state;
        const {
            animationDuration,
            buttonProps = {},
            children,
            hasStickyHeader,
            headerActionItems,
            title,
            onClick,
            ...args
        } = this.props;

        const buttonClassName = hasStickyHeader
            ? 'collapsible-card-header has-sticky-header'
            : 'collapsible-card-header';

        return (
            <CollapsibleWrapper {...args} className={isOpen ? "is-open": ""}>
                <div className={buttonClassName}>
                    <TriggerButton
                        className="collapsible-card-title"
                        onClick={() => onClick ? onClick() : this.toggleVisibility()}
                        type="button"
                    >
                        {title}
                        {/* <IconCaretDown className="collapsible-card-header-caret" color={nines} width={8} /> */}
                    </TriggerButton>
                    {isOpen && headerActionItems}
                </div>
                <div
                    className="collapsible-card-transition"
                    ref={this.transitionElement}
                    style={{
                        height,
                        transitionDuration: `${animationDuration}ms`,
                    }}
                >
                    <div className="collapsible-card-content">{children}</div>
                </div>
            </CollapsibleWrapper>
        );
    }
}

export default Collapsible;