import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
`;

export const Flex = Grid;

export const Flex1 = styled.div`
  flex: 1;
`;

export const Flex2 = styled.div`
  flex: 2;
`;

export const Spacer = Flex1;

export const Relative = styled.div`
  position: relative;
`

export const ResponsiveFlex = styled(Flex)`
  @media (max-width: 776px) {
    flex-direction: column;
  }
`