import React from "react";
import useFetch from "hooks/useFetch";
import { Link } from "react-router-dom";
import StoreCard from "components/StoreCard";
import { Relative } from "components/Flex";
import useTitle from "hooks/useTitle";

export default ({ user }) => {
  useTitle("Order History");
  const historyRequest = useFetch(
    `${process.env.REACT_APP_BAM_API_URL}/api/history`,
    {            
      audience: process.env.REACT_APP_BAM_API_URL,
      scope: 'read:merchants',
      responseType: 'token id_token' 
    }
  );
  if (!historyRequest?.response?.map) {
    return "loading"
  }
  return (
    <div className="order-history-page">
      <h1>Order History</h1>
      
      {historyRequest?.response?.map((l) => (
          <Relative style={{ height: 420 }}>
            <StoreCard
              href={`/history/${l.merchant_id}`}
              location={l}
              buttonText="View Orders"
            />
          </Relative>
        ))}
    </div>
  );
};
