import React from "react";
import styled from 'styled-components'
import PriceLabel, { formatPriceLabelWithName } from 'components/PriceLabel'
import Immutable from 'immutable';

const Input = styled.input``

export const ProductModifierSelect = ({
  name = "",
  modifiers = [],
  onChange = () => {},
  selectedModifierIds = new Immutable.Set([]),
}) => (
  <select
    data-testid="select"
    name={name}
    value={modifiers.find(m => selectedModifierIds.has(m.id))?.modifierData?.name || modifiers[0]?.modifierData.name}
    onChange={(e) =>
      onChange(
        modifiers.find(
          ({ modifierData }) => modifierData.name === e.currentTarget.value
        ).id
      )
    }
  >
    {modifiers.map(({ modifierData }) => (
      <option
        data-testid="select-option"
        key={modifierData.name}
        name={modifierData.modifierListId}
        value={modifierData.name}
      >
        {formatPriceLabelWithName(modifierData)}
      </option>
    ))}
  </select>
);

export const ProductModifierCheckboxes = ({
  name = "",
  modifiers = [],
  onChange = () => {},
  selectedModifierIds = new Immutable.Set([]),
}) => (
  <div data-testid="checkboxes">
    {modifiers.map((m) => (
      <div key={m.id}>
        <Input
          checked={selectedModifierIds.has(m.id)}
          type="checkbox"
          id={m.id}
          name={name}
          value={m.id}
          onChange={() => onChange(m.id)}
        />
        <PriceLabel htmlFor={m.id} name={name} modifierData={m.modifierData} />
      </div>
    ))}
  </div>
);

export const ProductModifier = ({ selectedModifierIds = new Immutable.Set([]), modifiers = [], onChange = () => {} }) => (
  <>
    {modifiers.map(({ modifierListData }) => (
      <div key={modifierListData.name}>
        <b>{modifierListData.name}</b>
        {modifierListData.selectionType === "SINGLE" ? (
          <ProductModifierSelect
            selectedModifierIds={selectedModifierIds}
            name={modifierListData.name}
            modifiers={modifierListData.modifiers}
            onChange={onChange}
          />
        ) : (
          <ProductModifierCheckboxes  
            selectedModifierIds={selectedModifierIds}
            name={modifierListData.name}
            modifiers={modifierListData.modifiers}
            onChange={onChange}
          />
        )}
        <br />
      </div>
    ))}
  </>
);

export default ProductModifier;
