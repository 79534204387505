import styled from 'styled-components';

export const Star = styled.div`
  background-image: url("https://static.overlay-tech.com/assets/142a3857-98b3-464b-8828-6b9204eab13e.svg");
  background-repeat: no-repeat;
  width: 136px;
  height: 42px;
`;

export const StarBand = styled.div`
  background-image: url("https://static.overlay-tech.com/assets/142a3857-98b3-464b-8828-6b9204eab13e.svg");
  background-repeat: space;
  width: 100%;
  height: 50px;
`;

export default StarBand