import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import Collapsible from "components/Collapsible";
import SquarePaymentForm from "components/SquarePaymentForm";
import useSquare from "hooks/useSquare";
import { Flex, Flex1, Flex2 } from "components/Flex";
import { Input } from "components/Form";
import { Radio, RadioGroup } from "components/RadioGroup";
import PlacesAutocomplete from 'react-google-autocomplete';
import useTitle from "hooks/useTitle";

import "react-datepicker/dist/react-datepicker.css";

const PICKUP = "Pickup";
const DELIVERY = "Delivery";
const SHIPPING = "Shipping";

const GooglePlacesAutocomplete = styled(PlacesAutocomplete)`
    width: calc(100% - 36px);
    background-color: rgba(0,0,0,0.05);
    color: rgba(0,0,0,0.7);
    border-radius: 8px;
    padding: 16px;
    font-size: 14px;
    -webkit-transition: border-color 0.15s ease;
    transition: border-color 0.15s ease;
    color: black;
    border: 1px solid transparent;
`
const CartDialog = styled.div`
  margin: auto;
  margin-top: 64px;
  max-width: 1024px;
`;

const Step = styled.div`
  width: 26px;
  background-color: black;
  color: white;
  border-radius: 100%;
  height: 26px;
  line-height: 27px;
  font-weight: bold;
  font-size: 16px;
`
const StepName = styled.div`
  font-size: ${props => props.isMobile ? "16px" : "22px"};
  margin-top: ${props => props.isMobile ? "6px" : "0px"};
  margin-left: 6px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CollapsibleWithMargin = styled(Collapsible)`
  margin: 4px;
  max-width: calc(100% - 8px);
`

const OrderDetails = styled.div`
  width: 100%;
  margin-bottom: 24px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  border-radius: 4px;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: rgba(0,0,0,0.7);
  padding: 10px;
`

const NextButton = styled.button`
  background-color: rgba(0,0,0,0.8);
  border: none;
  width: 100%;
  height: 48px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  transition: background-color 0.15s ease;
  margin-bottom: 60px;
`
const Relative = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 9001;
  width: 120px;
  pointer-events:none;
`;

const FullfilmentPills = styled.div`
  display: flex;
  margin: 20px; 
  
  button[aria-selected='true'] {
    background-color: #4CAF50;
    color: white;
  }
  button[aria-selected='false'] {
    background-color: transparent;
  }
  button:first-child {
    border: 1px solid #c1c1c1;
    border-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  } 
  button {
    font-weight: bold;
    padding: 20px;
    flex: 1;
    border: none;
    border: 1px solid #c1c1c1;
  }
  button:last-child {
    border: 1px solid #c1c1c1;
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  } 
`

const ErrorBlock = styled.div`
    margin: 10px 0;
    padding: 14px 10px;
    color: #222;
    border-radius: 4px;
    background-color: #fdebee;
    border: 1px solid #f69bab;
`

const BAMDatePicker = styled(DatePicker)`
  width: calc(100% - 36px);
  background-color: rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.7);
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  -webkit-transition: border-color 0.15s ease;
  transition: border-color 0.15s ease;
  color: black;
  border: 1px solid transparent;
`

export const CheckoutPage = ({
  user,
  locations = {},
  cart = {},
  setCart,
  isMobile,
}) => {
  useTitle("Checkout");
  const [receipt, setReceipt] = useState(null);
  const [orders, setOrders] = useState([]);
  const [pickupDate, setPickupDate] = useState({});
  const [pickupDateText, setPickupDateText] = useState({});// this is a hack i suck
  const squareStatus = useSquare();
  const [isContactInfoOpen, setIsContactInfoOpen] = useState(!user);
  const [isOrderOpen, setIsOrderOpen] = useState([]);
  const [isPaymentInfoOpen, setIsPaymentInfoOpen] = useState(false);
  const [shouldShowRequiredFields, setShouldShowRequiredFields] = useState(false)
  const [fname, setFname] = useState(user?.first_name)
  const [lname, setLname] = useState(user?.last_name)
  const [phone, setPhone] = useState(user?.phone_number)
  const [email, setEmail] = useState(user?.email)
  const [fullfilmentType, setFullfilmentType] = useState(PICKUP);
  const [shippingAddress, setShippingAddress] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BAM_API_URL}/api/checkout/calculate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cart,
        name: user?.displayName || "test",
      }),
    })
      .then(async res => { 
        if (res.status === 500) {
          setCart({});
          return [];
        }
        return await res.json()
      })
      .then(orders => {
        setOrders(orders)
        const openOrders = orders.map(o => false);
        if (!!user) {
          openOrders[0] = true
        }
        setIsOrderOpen(openOrders)
      })
  }, [user, cart]);

  if (!orders) {
    return "Loading";
  }
  if (receipt) {
    let fullfilment = "Please pickup your order on ";
    if (fullfilmentType === DELIVERY) {
      fullfilment = "Your order will be delivered "
    }
    if (fullfilmentType === SHIPPING) {
      fullfilment = "Your order will be shipped"
    }
    return (
      <div className="receipt">
        <h1>Payment Complete </h1>
        {receipt.map(payment => (
          <li>
            Your card will see a charge from{" "}
            {payment.cardDetails?.statementDescription} for{" $"}
            {(payment.totalMoney?.amount / 100).toFixed(2)}{". "}
            {fullfilment} 
            {pickupDate[payment.locationId]?.toDateString()} 
            {fullfilmentType !== SHIPPING && ' at '} 
            {pickupDate[payment.locationId]?.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}
            .
          </li>
        ))}
      </div>
    );
  }
  if (!Array.isArray(orders)) {
    return "Loading...";
  }

  const zip = shippingAddress?.address_components?.find(({ types }) => 
    types.find(t => t === "postal_code")
  )
  const stateAbbreviation = shippingAddress?.address_components?.find(({ types }) => 
    types.find(t => t === "administrative_area_level_1")
  )

  const total = orders.reduce((acc, order) => acc + order.totalMoney.amount, 0)
  const taxes = orders.reduce((acc, order) => acc + order.totalTaxMoney.amount, 0)
  const subTotal = total - taxes;

  return (
    <CartDialog>
      <Flex>
        <Flex2>
          <FullfilmentPills>
            <button 
              onClick={() => setFullfilmentType(PICKUP)}
              aria-selected={fullfilmentType === PICKUP}
            >
              Pickup
            </button>
            <button 
              onClick={() => setFullfilmentType(DELIVERY)}
              aria-selected={fullfilmentType === DELIVERY}
            >
              Delivery
            </button>
            {/* <button 
              onClick={() => setFullfilmentType(SHIPPING)}
              aria-selected={fullfilmentType === SHIPPING}
            >
              Shipping
            </button> */}
          </FullfilmentPills>
          <CollapsibleWithMargin 
            isOpen={isContactInfoOpen} 
            onClick={() => setIsContactInfoOpen(!isContactInfoOpen)} 
            title={<Flex><Step>1</Step><StepName>Contact Information</StepName></Flex>}
          >
            <form onSubmit={e => {
              e.preventDefault();
              const inputs = e.currentTarget.querySelectorAll('input');
              const [email, fname, lname, phone] = inputs;
              if (email.value === "" || fname.value === "" || lname.value === "" || phone.value === "") {
                setShouldShowRequiredFields(true)
              } else {
                setIsContactInfoOpen(false)
                setIsOrderOpen([true, ...isOrderOpen.slice(1)])
              }
            }}>
              <Input 
                isValid={shouldShowRequiredFields} 
                onChange={e => setEmail(e.currentTarget.value)} 
                value={email} 
                type="email" 
                name="email" 
                placeholder="Email" 
              />
              <br/>
              <Input 
                isValid={shouldShowRequiredFields} 
                onChange={e => setFname(e.currentTarget.value)} 
                value={fname} 
                name="fname" 
                placeholder="First Name" 
              />
              <br/>
              <Input 
                isValid={shouldShowRequiredFields} 
                onChange={e => setLname(e.currentTarget.value)} 
                value={lname} 
                name="lname" 
                placeholder="Last Name" 
              />
              <br/>
              <Input 
                isValid={shouldShowRequiredFields}
                value={phone}
                onChange={e => setPhone(e.currentTarget.value)} 
                type="tel"        
                pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}"
                name="phoneNumber" 
                placeholder="Phone Number"
                title="Phone number should contain 10 digits"
              />
              <br/>
              <NextButton className="next" type="submit">Next</NextButton>
            </form>
          </CollapsibleWithMargin>
          {orders.map((order, i) => {
            const isZipCodeValid = zip && !order.location.zip.startsWith(zip.short_name);
            const isStateAbbrevValid = zip && !(order.location.state === stateAbbreviation.short_name);
            const isAddressValid = fullfilmentType === SHIPPING ? isStateAbbrevValid : isZipCodeValid;

            return (
              <>
                {fullfilmentType !== PICKUP && <CollapsibleWithMargin 
                  onClick={() => {
                    setPickupDate({ ...pickupDate, [order.locationId]: pickupDate[order.locationId] || new Date() })
                    setIsOrderOpen([...isOrderOpen.slice(0, i), !isOrderOpen[i], ...isOrderOpen.slice(i+1)])
                  }} 
                  isOpen={isOrderOpen[i]} 
                  title={<Flex><div><Step>{i + 2}</Step></div><div><StepName isMobile={isMobile}>{fullfilmentType} address</StepName></div></Flex>}
                >
                  {isAddressValid && (
                    <ErrorBlock>
                      <b>Error: </b> {fullfilmentType === SHIPPING ? "State" : "Zip code"} must be same as merchant
                    </ErrorBlock>
                  )}
                  {!isAddressValid && <div style={{ height: 56 }} />}
                  <GooglePlacesAutocomplete
                    onPlaceSelected={places => setShippingAddress(places)}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "us" },
                    }}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} 
                  />
                </CollapsibleWithMargin>}
                {fullfilmentType !== SHIPPING && <CollapsibleWithMargin 
                  onClick={() => {
                    setPickupDate({ ...pickupDate, [order.locationId]: pickupDate[order.locationId] || new Date() })
                    setIsOrderOpen([...isOrderOpen.slice(0, i), !isOrderOpen[i], ...isOrderOpen.slice(i+1)])
                  }} 
                  isOpen={isOrderOpen[i]} 
                  title={<Flex><div><Step>{i + 2}</Step></div><div><StepName isMobile={isMobile}>{fullfilmentType} Time for {locations[order.locationId]?.name}</StepName></div></Flex>}
                >
                  <h1>{fullfilmentType} From {locations[order.locationId]?.name}</h1>
                  <BAMDatePicker
                    showTimeSelect
                    minDate={new Date()}
                    minTime={new Date().setMinutes(new Date().getMinutes() + 30)}
                    maxTime={new Date().setHours(23, 59, 59)}
                    dateFormat="MMMM d, yyyy h:mmaa"
                    selected={pickupDate[order.locationId] || (new Date().setHours(new Date().getHours() + 1))}
                    onChange={date => {
                      setPickupDate({ ...pickupDate, [order.locationId]: date })
                      setPickupDateText({ 
                        ...pickupDateText, 
                        [order.locationId]: date.toLocaleString("en-US", { timeZone: "US/Central" }) 
                      });
                      if (orders.length-1 !== i) {
                        setIsOrderOpen([...isOrderOpen.slice(0, i), !isOrderOpen[i], !isOrderOpen[i+1], ...isOrderOpen.slice(i+2)])
                      } else {
                        setIsOrderOpen([...isOrderOpen.slice(0, i), false])
                        setIsPaymentInfoOpen(true)
                      }
                    }}
                  />
                </CollapsibleWithMargin>}
              </>
            )
          })}
          <CollapsibleWithMargin 
            isOpen={isPaymentInfoOpen} 
            onClick={() => setIsPaymentInfoOpen(!isPaymentInfoOpen)} 
            title={<Flex><Step>{orders.length + 2}</Step><StepName>Payment Info</StepName></Flex>}
          >
            {squareStatus === "ERROR" && "Failed to load SquareSDK. Please refresh the page."}
            {squareStatus === "SUCCESS" && (
              <SquarePaymentForm
                fullfilmentType={fullfilmentType}
                shippingAddress={shippingAddress.formatted_address}
                phoneNumber={phone}
                pickupDate={pickupDate}
                pickupDateText={pickupDateText}
                onPaymentDone={payment => {
                  setCart({});
                  setReceipt(payment);
                }}
                orderCount={orders.length}
                cart={cart}
                paymentForm={window.SqPaymentForm}
                user={user}
                fname={fname}
                lname={lname}
                phone={phone}
                email={email}
              />
            )}
          </CollapsibleWithMargin>
        </Flex2>
        {!isMobile && <Flex1>
          <OrderDetails>      
            {orders.map(order => (
              <>
                <h1>{locations[order.locationId]?.name}</h1>
                {/* <button>{order.line_items.length} {order.line_items.length > 1 ? "Items" : "Item"}</button> */}
                <h2>${(order.totalMoney.amount/100).toFixed(2)}</h2>
              </>
            ))}
            <hr />
            {taxes ? <h2>Subtotal: ${(subTotal/100).toFixed(2)}</h2> : null}
            {taxes ? <h2>Taxes &amp; Fees: ${(taxes/100).toFixed(2)}</h2> : null}
            <h2>Total: ${(total/100).toFixed(2)}</h2>
          </OrderDetails>
        </Flex1>}
      </Flex>
    </CartDialog>
  );
};

export default CheckoutPage;
