import React from 'react'
import styled from 'styled-components'

export const Label = styled.label`
  font-size: 16px;
  line-height: 32px;
`

export const StyledInput = styled.input`
  width: calc(100% - 36px);
  background-color: rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.7);
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  transition: border-color 0.15s ease;
  color: black;
  border: ${props => !!props.isValid ? '1px solid #E53935' : "1px solid transparent"};

  .invalid {
    border: 1px solid #d92b2b;
  }
`

const InvalidText = styled.div`
  height: ${props => !!props.isValid ? 'auto' : 0};
  color: #d92b2b;
  overflow: hidden;
  font-size: 16px;
  margin: 2px;
`

export const Input = props => {
  return (
    <>
      <StyledInput {...props} />
      <InvalidText {...props} >Field Required</InvalidText>
    </>
  )
}