import React from "react";
import styled from "styled-components";
import TexasFlag from "icons/TexasFlag";
import bamLogo from 'icons/img/bam_logo.png'
import squareRegister from 'icons/img/square_register.png'
import { Link } from 'react-router-dom';

const Flex = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  list-style: none;
  padding: 20px 0;
`;

const Carousel = styled(Flex)`
  list-style: none;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  list-style: none;
  height: 330px;
  margin-top: 6px;
  margin-left: 20px;
  padding-left: 440px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Section = styled.section`
  width: 100%;
  div.inner {
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    max-width: 800px;
    margin: 0 auto;
  }
`
const CardWrapper= styled.div`  
  position: relative;
`
const Card = styled.section`
  display: flex;
  flex-direction: ${props => props.isMobile ? "column" : "row"};
  position: absolute;
  background-color: white;
  max-width: 1000px;
  border-radius: 40px;
  margin: 0 auto;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 2px 14px 14px 10px rgba(0, 0, 0, 0.25);
`
const Main = styled.main`
  margin: 0px auto;
`
const ButtonLink = styled(Link)`
  background-color: #C3333B;
  padding: 10px 36px;
  border-radius: 40px;
  color: white;
  font-weight: bold;
  position: relative;
  top: 10px;
`
const Padding10 = styled.div`
  padding: 10px;
`
const Padding20 = styled.div`
  padding: 20px;
`

const Logo = styled.img`
  max-width: 50%;
`

const CityLink = styled(Link)`
    font-size: 20px;
    line-height: 34px;
`

const Ol = styled.ol`
  li {
    font-size: 2em;
      h3 {
        font-size: 1em;
        margin: 4px;
      }
      p {
        font-size: .5em;
        margin: 4px;
      }
      margin: 20px;
  }
`

export const HomePage = ({ isMobile }) => (
  <Main>
    <Section isMobile={isMobile} style={{ backgroundColor: 'white' }}>
      <div className="inner">
        <Logo src={bamLogo} alt="bam logo" />
        <Padding20>
          <h1>Our Cities</h1>
          <ul>
            <li><CityLink to="/browse/76448" alt="Eastland, TX">Eastland, TX</CityLink></li>
            <li><CityLink to="/browse/76401" alt="Stephenville, TX">Stephenville, TX</CityLink></li>
          </ul>
        </Padding20>
      </div>
    </Section>
    <Section isMobile={isMobile} style={{ paddingBottom: 140 }}>
      {/* <div>
        Slider
      </div> */}
      <div className="inner">
        <Padding20>
          <h2>How BAM works</h2>
          <p>BAM helps you find great American products in local stores near you. Place an order online and pickup in store.</p>
          <Ol>
            <li>
              <h3>Discover</h3>
              <p>Enter your zipcode to find the best local stores near you</p>
            </li>
            <li>
              <h3>Shop American Products</h3>
              <p>Shop from a single store or combine items from multiple stores in the save order</p>
            </li>
            <li>
              <h3>Pickup when ready</h3>
              <p>Pickup your order and rest easy knowing you support local, American bussinesses. Save $10 on your next order when you refer a friend to BAM.</p>
            </li>
          </Ol>
        </Padding20>
      </div>
    </Section>
    <CardWrapper>
      <Card isMobile={isMobile}>
        <Padding20>
          <h2>About Us</h2>
          <p>We are a Texas based family trying to help small businesses sell their products online</p>
          <ButtonLink to="/about">Learn More</ButtonLink>
        </Padding20>
        <TexasFlag width={isMobile ? 300 : undefined} />
      </Card>
    </CardWrapper>
    <Section isMobile={isMobile} style={{ 
      backgroundColor: 'white', 
      paddingTop: isMobile ? 320 : 200,
      paddingBottom: isMobile ? 100 : 0,
    }}>
    <div className="inner">
        <img src={squareRegister} alt="bam logo" />
        <Padding10>
          <h2>Sell on BAM</h2>
          <p>Are you a small business looking to sell your products on buybam.com?</p>
          <ButtonLink to="/merchant">Become a Merchant</ButtonLink>
        </Padding10>
      </div>
    </Section>
  </Main>
)

export default HomePage;
