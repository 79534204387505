import React, { useRef } from 'react';
import styled from 'styled-components';
import useCreatePortal from '../hooks/useCreatePortal';

const Modal = styled.div`
    background: rgba(0,0,0,.3);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
`

const Card = styled.div`
    background-color: white;
    width: 420px;
    min-height: 260px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

export const Dialog = ({ close, children, ...props }) => {
    const createBodyPortal = useCreatePortal();
    return createBodyPortal(
        <Modal onClick={close} {...props}>
            <Card onClick={e => e.stopPropagation()}>
                {children}
            </Card>
        </Modal>
    );
}

export default Dialog;