import { Flex, Flex1 } from "components/Flex";
import TexasFlag from "icons/TexasFlag";
import React from "react";
import styled from "styled-components";
import hayden from "icons/img/hayden.jpg";
import james from "icons/img/james.jpeg";
import seth from "icons/img/seth.png";
import useTitle from "hooks/useTitle";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

const Card = styled.section`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-size: 24px;
  background: white;
  border-radius: 40px;
`;

const BigCard = styled(Card)`
  width: calc(100% - 100px);
  max-width: 1024px;
  padding: 30px;
  margin: 0 auto;
`;
const SmallCard = styled(Card)`
  margin: 10px;
  padding: 10px;
  flex: 1;
  text-align: center;
`;

const RoundedImg = styled.img`
  border-radius: 40px;
  max-width: 100%;
`;

const ResponsiveFlex = styled(Flex)`
  @media (max-width: 776px) {
    flex-direction: column;
  }
`

const WhoWeAreWrapper = styled.div`
  max-width: calc(100% - 48px);
  margin: 0 auto;
`

const Title = styled.h1`
  color: ${props => props.theme.colors.black};
  font-family: ${props =>
    props.theme.fonts.roboto31Bold.family};
  font-size: ${props =>
    props.theme.fonts.roboto31Bold.size};
  font-weight: ${props =>
    props.theme.fonts.roboto31Bold.weight};
  line-height: ${props =>
    props.theme.fonts.roboto31Bold.lineHeight};
  margin: 10px 0 0 0;
`

const Text = styled.p`
  color: ${props => props.theme.colors.black};
  font-family: ${props =>
    props.theme.fonts.roboto18Light.family};
  font-size: ${props =>
    props.theme.fonts.roboto18Light.size};
  font-weight: ${props =>
    props.theme.fonts.roboto18Light.weight};
  line-height: ${props =>
    props.theme.fonts.roboto18Light.lineHeight};
  margin: 0;
`

const MainHeroSection = () => (
  <ResponsiveFlex style={{ margin: 20 }}>
    <Flex1>
      <RoundedImg
        alt=""
        src="https://static.overlay-tech.com/assets/9a87d79e-2634-4d83-b38f-a98a444636ad.png"
      />
    </Flex1>
    <Flex1 style={{ marginLeft: 20 }}>
      <Title>American Products</Title>
      <Text>
        We thoroughly vet our merchants to ensure that the products they list
        are made in the USA.
      </Text>
      <Title>Local Businesses</Title>
      <Text>
        We don’t support large chains or conglomerates. We know Americans value
        helping out their community and supporting local businesses.
      </Text>
      <Title>100% Quality</Title>
      <Text>
        We know Americans only accept the best. We use your ratings in
        conjunction with our review process to ensure that the merchants listed
        on BuyBam.com only have top tier American Made products.
      </Text>
    </Flex1>
  </ResponsiveFlex>
);

const TexasProudSection = () => (
  <BigCard>
    <ResponsiveFlex>
      <Flex1>
        <Title>Texan Proud</Title>
        <Text>
          We are a Texas based family trying to help small businesses sell their products online.
        </Text>
        <br />
        <Text>We love this country and support those who do too</Text>
        <br />
        <Text>
          At BAM, we know its not just enough to like and subscribe We support
          what we believe in through action.
        </Text>
      </Flex1>
      <Flex1>
        <TexasFlag width="100%"/>
      </Flex1>
    </ResponsiveFlex>
  </BigCard>
);

const MeetOurTeam = () => (
  <>
    <h1 style={{ textAlign: "center" }}>Meet Our Team</h1>
    <ResponsiveFlex style={{ maxWidth: "calc(100% - 24px)", margin: '0 auto' }}>
      <SmallCard>
        <RoundedImg
          alt="James Burger"
          src={james}
        />
        <div>
          CEO/President
          <br />
          <div>James Burger</div>
        </div>
      </SmallCard>

      <SmallCard>
        <RoundedImg
          alt="Hayden Burger"
          src={hayden}
        />
        <div>
          VP of Research &amp; Design
          <br />
          <div>Hayden Burger</div>
        </div>
      </SmallCard>
      <SmallCard>
        <RoundedImg
          alt="Seth Chitester"
          src={seth}
        />
        <div>
          VP of Sales and Marketing
          <br />
          <div>Seth Chitester</div>
        </div>
      </SmallCard>
    </ResponsiveFlex>
  </>
);

const WhoWeAre = () => (
  <WhoWeAreWrapper>
    <Title style={{ textAlign: "center" }}>Who We Are</Title>
    <br />
    <Text>
      Started in May 2020, Buy American Made LLC (BAM) was formed in order to
      build a better tomorrow through e-commerce. The company began on building
      a website that will connect online shoppers to fully vetted American made
      products. The BAM brand is built upon the concept of providing
      high-quality products and services that are unmatched by the competition.
    </Text>
    <br />
    <Text>
      During June of 2020, BAM purchased BuyBAM.com and set out to build a
      cutting-edge e-commerce platform. BAM partnered with Square, another
      American owned company, to handle all of the card processing.
    </Text>
    <br />
    <Text>
      This exciting partnership has built a dependable platform that helps local
      businesses grow. BAM remains focused on local communities needs and online
      shoppers desires to generate an online shopping experience that is second
      to none.
    </Text>
    <br />
    <Text>
      BAM offers a full business sales program including custom sales packages,
      Sales Assisted Service (SAS), Point-of-Sales System (POS) and much more.
    </Text>
    <br />
    <Text>
      With Square POS systems, BAM has the ability to manage local inventory,
      stock levels can be adjusted quickly in order to respond to increased
      demand and inventory is updated live as sales are completed. BAM offers
      merchants the ability to set up delivery and shipping options to meet the
      demand of the stay-at-home shopper and comes ready to meet the in and out
      shoppers with the in store pick-up option.
    </Text>
    <br />
    <Text>
      This is just a small part of the BAM success story. An up-and-coming
      company, BAM is a reputable, well-founded e-commerce business of today.
      BAM is creating a solid, reliable way of supporting and responding to the
      needs of the community. Thanks again for shopping at BAM! For any
      additional questions about BAM please contact us at support@buybam.com.
    </Text>
  </WhoWeAreWrapper>
);

export default () => {
  useTitle("About Us");
  return (
    <Wrapper>
      <MainHeroSection />
      <TexasProudSection />
      <br />
      <br />
      <MeetOurTeam />
      <br />
      <br />
      <WhoWeAre />
      <br />
      <br />
    </Wrapper>
  )
};
