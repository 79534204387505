import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Location from "icons/Location";

const ShopNumProducts = styled.p`
  background: #4CAF50;
  width: 160px;
  margin: 10px;
  float: right;
  font-size: 16px;
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 20px;
  text-align: center;
`

const Card = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 420px;
  width: calc(100% - 40px);
  height: 400px;
  color: #424242;
  background: white;
  margin-left: 20px;
  box-shadow: 3px 3px 10px rgba(27,33,38,.16), 3px 4px 8px 0 rgba(27,33,38,.22);
  border-radius: 42px;
  position: absolute;
  z-index: 10;
`

const CoverPhoto = styled.div`
  background: #e5d2b4;
  background-image: url(${(props) => props.src});
  background-size: cover;
  height: 500px;
  position: relative;
  border-top-right-radius: 42px;
  border-top-left-radius: 42px;
`;

const LocationName = styled.h1`
  margin: 0;
  margin-top: 16px;
  padding-left: 10px;
`

const StoreAddress = styled.p`
  line-height: 18px;
  margin-left: 10px;
`

const LocationBumpedDownALittle = styled(Location)`
  position: relative;
  top: 2px;
`

const RightAlign = styled.div`
  text-align: center;
`


export const StoreCard = ({ location, href, buttonText="Shop Products" }) => (
  <Link class="storeLink" to={href}>
    <Card>
      <CoverPhoto src={location.square_metadata?.logoUrl} />
      <LocationName>{location.name}</LocationName>
      <StoreAddress>
        <LocationBumpedDownALittle fill="black" size="16px" />
        &nbsp;{location.address}, {location.city}, {location.state},{" "}
        {location.zip}
      </StoreAddress>
      <RightAlign>
        <ShopNumProducts>{buttonText}</ShopNumProducts>
      </RightAlign>
    </Card>
  </Link>
);

export default StoreCard;