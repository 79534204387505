import { useEffect } from "react";

/**
 * This hook can be used with useRef to figure out if you clicked outside of the referenced element. 
 * 
 * EXAMPLE USAGE:
import React, { useRef } from "react";

import useOutsideClick from "./hooks/useOutsideClick";

function MyComponent() {
  // 1. Create a ref
  const ref = useRef();
  
  // 2. Pass the ref and a callback function to this hook.
  //    The callback function will run everytime someone clicks outside the element.
  useOutsideClick(ref, () => {
    alert('You clicked outside')
  });

  // 3. Add the ref to your "host component" AKA the component you want to be notified about whenver someone clicks outside of it.
  return (
      <div ref={ref}}>
        <h4>This is a menu</h4>
        <p>This is another content</p>
      </div>
  );
}
 * @param {*} ref 
 * @param {*} callback 
 */
export const useClickOutside = (ref, callback, ...ignore) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      // If there are no ignore refs then just call the call back
      const ignoredRefs = Array.prototype.slice.call(ignore);
      if (ignoredRefs.length === 0 || ignoredRefs.some(ref => ref.current && !ref.current.contains(e.target))) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export default useClickOutside;