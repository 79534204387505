import React, { useReducer, useState } from "react";
import styled from "styled-components";
import Add from "icons/Add";
import Remove from "icons/Remove";
// import Big from "big.js";
import { Flex } from "./Flex";
import { generateCartCountRange } from "../util";

const Wrapper = styled(Flex)`
  height: ${(props) => props.height || 70}px;
`;

const RemoveButton = styled.button`
  background-color: ${(props) => props.theme.colors.fireBrick};
  border: 0;
  outline: 0;
  border-top-left-radius: ${(props) => props.height || 70}px;
  border-bottom-left-radius: ${(props) => props.height || 70}px;
  flex: 1;
  height: ${(props) => props.height || 70}px;
`;

const AddButton = styled.button`
  background-color: ${(props) => props.theme.colors.midnightBlue};
  border: 0;
  outline: 0;
  border-top-right-radius: ${(props) => props.height || 70}px;
  border-bottom-right-radius: ${(props) => props.height || 70}px;
  flex: 1;
  height: ${(props) => props.height || 70}px;
`;
const DropdownWrapper = styled.div`
  display: flex;
  flex: 1;
  * {
    flex: 1;
  }
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGray};
  border-top: 1px solid ${(props) => props.theme.colors.darkGray};
`;
const Dropdown = styled.select`
  font-size: 28px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  outline: 0;
  text-align-last: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  border: 0;
  background-color: white;
`;

const AddToCartButton = styled.button`
  font-size: 28px;
  background-color: ${(props) => props.theme.colors.midnightBlue};
  color: white;
  border: 0;
  outline: 0;
  border-radius: ${(props) => props.height || 70}px;
  flex: 1;
  height: ${(props) => props.height || 70}px;
`

const Pill = ({
  countOptions,
  count,
  minAmount,
  maxAmount,
  height = 70,
  onChange = () => {},
}) => {
  const selectedIndex = countOptions.findIndex((x) => Number.parseFloat(x).toFixed(2) === Number.parseFloat(count).toFixed(2));
  return (
    <>
      <RemoveButton
        height={height}
        onClick={() => {
          const newIndex = selectedIndex - 1;
          if (newIndex > 0) {
            onChange(countOptions[newIndex]);
          } else {
            onChange(0);
          }
        }}
      >
        <Remove fill="white" />
      </RemoveButton>
      <DropdownWrapper>
        <div />
        {/* For some dumb reason we can't center a select on anything other then chrome */}
        <Dropdown
          value={selectedIndex}
          onChange={(e) =>
            onChange(countOptions[parseFloat(e.currentTarget.value)])
          }
        >
          {countOptions.map((x, i) => (
            <option key={i} value={i}>
              {x}
            </option>
          ))}
          {/* accessible though :) */}
        </Dropdown>
        <div />
        {/* These spaces will center but create deadzones that will eventually be reported as a bug */}
      </DropdownWrapper>
      <AddButton
        height={height}
        onClick={() => {
          const newIndex = selectedIndex + 1;
          if (newIndex < countOptions.length) {
            onChange(countOptions[newIndex]);
          } else {
            onChange(countOptions[countOptions.length - 1]);
          }
        }}
      >
        <Add fill="white" />
      </AddButton>
    </>
  );
};

export default ({
  count = 0,
  price,
  onChange,
  step,
  minAmount,
  maxAmount,
  height = 70,
  ...props
}) => {
  const countOptions = generateCartCountRange(step, minAmount, maxAmount);

  return (
    <Wrapper height={height} {...props}>
      {
        count === 0 
        ? <AddToCartButton 
            className="addToCart" 
            onClick={() => onChange(minAmount)}
          >
            Add ${price}
          </AddToCartButton>
        : <Pill
            count={count}
            countOptions={countOptions}
            step={step}
            height={height}
            minAmount={minAmount}
            maxAmount={maxAmount}
            onChange={onChange}
          />
      }

    </Wrapper>
  );
};
