import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Switch, Route, Link } from "react-router-dom";
import _keyBy from "lodash.keyby";
import StoreCatelogPage from "pages/StoreCatelogPage";
import ProductDetailsPage from "pages/ProductDetailsPage";
import TosPage from "pages/TosPage";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import CheckoutPage from "pages/CheckoutPage";
import useFetch from "hooks/useFetch";
import useLocalStorage from "hooks/useLocalStorage";
import Header from "components/Header";
import Footer from "components/Footer";
import MerchantPage from "pages/MerchantPage";
import HomePage from "pages/HomePage";
import EditProfilePage from "pages/EditProfilePage";
import OrderHistoryPage from "pages/OrderHistoryPage";
import OrderHistoryDetailsPage from "pages/OrderHistoryDetailsPage";
import { useMediaQuery } from "react-responsive";
import PrivateRoute from "components/PrivateRoute";
import AboutPage from "pages/AboutPage";
import ContactPage from "pages/ContactPage";
import GdprPage from "pages/GdprPage";
import SearchPage from "pages/SearchPage";
import LogRocket from "logrocket";
import { searchStateToUrl, urlToSearchState, createURL } from "./util";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth0, Auth0Provider } from "@auth0/auth0-react";
import BrowsePage from "pages/BrowsePage";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_PUBLIC_KEY
);

const AppWrapper = styled.div`
  margin-right: 0;
  @media (min-width: 1440px) {
    margin-right: 480px;
  }
`;

function App() {
  const { user } = useAuth0();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });
  const locationsReq = useFetch(
    `${process.env.REACT_APP_BAM_API_URL}/api/locations`
  );
  const [cart, setCart] = useLocalStorage("cart", {});
  useEffect(() => {
    if (user?.id) {
      LogRocket.identify(user.id, {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });
    }
  }, [user]);
  const location = useLocation();
  const history = useHistory();
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);
  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", "search", {
        search_term: urlToSearchState(location).query
      });
    }
  }, [])
  const DEBOUNCE_TIME = 1000;
  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetState);
    setDebouncedSetState(
      setTimeout(() => {
        history.push(searchStateToUrl(updatedSearchState));
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };
  const numberOfCartItems = Object.values(cart)
    .filter((x) => x)
    .reduce(
      (acc, item) => acc + Object.values(item).filter((x) => x).length,
      0
    );
  return (
    <InstantSearch
      indexName={process.env.REACT_APP_ALGOLIA_INDEX}
      searchClient={searchClient}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <AppWrapper>
        <Header
          numberOfCartItems={numberOfCartItems}
          user={user}
          isMobile={isMobile}
          isDesktop={isDesktop}
          user={user}
          cart={cart}
          setCart={setCart}
        />
        <Switch>
          <Route path="/checkout">
            <CheckoutPage
              isMobile={isMobile}
              user={user}
              locations={_keyBy(locationsReq.response, "id")}
              cart={cart}
              setCart={setCart}
            />
          </Route>
          <Route path="/merchant">
            <MerchantPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/history/:merchantId">
            <OrderHistoryDetailsPage />
          </Route>
          <Route path="/history">
            {user ? <OrderHistoryPage user={user} /> : "loading123"}
          </Route>
          <PrivateRoute path="/profile" component={EditProfilePage} />
          <Route path="/tos">
            <TosPage />
          </Route>
          <Route path="/search">
            <SearchPage cart={cart} setCart={setCart} />
          </Route>
          <Route path="/gdpr">
            <GdprPage />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicyPage />
          </Route>
          <Route path="/shop/:locationId/:productId">
            <ProductDetailsPage
              locations={locationsReq.response || []}
              cart={cart}
              setCart={setCart}
            />
          </Route>
          <Route path="/shop/:locationId">
            <StoreCatelogPage
              locations={locationsReq.response || []}
              cart={cart}
              setCart={setCart}
            />
          </Route>
          <Route path="/browse/:zipcode">
            <BrowsePage
              locations={locationsReq?.response || []}
              cart={cart}
              setCart={setCart}
            />          
          </Route>
          <Route path="/browse">
            <BrowsePage
              locations={locationsReq?.response || []}
              cart={cart}
              setCart={setCart}
            />          
          </Route>
          <Route path="/">
            <HomePage isMobile={isMobile} />
          </Route>
        </Switch>
        <Footer />
      </AppWrapper>
    </InstantSearch>
  );
}

export default App;
