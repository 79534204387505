/* This is the project stylesheet */

export default {
  colors: {
    bamDarkRed: "rgba(194,63,56,1)",
    black: "rgba(0,0,0,1)",
    darkGray: "rgba(151,151,151,1)",
    darkSlateGray: "rgba(37,57,84,1)",
    darkSlateGray2: "rgba(37,56,83,1)",
    fireBrick: "rgba(191,10,48,1)",
    fireBrick2: "rgba(195,51,59,1)",
    gray1: "rgba(51,51,51,1)",
    green: "rgba(15,169,88,1)",
    midnightBlue: "rgba(0,40,104,1)",
    midnightBlue2: "rgba(26,35,126,1)",
    steelBlue: "rgba(78,100,173,1)",
    transparentBlack: "rgba(0,0,0,0.25)",
    white: "rgba(255,255,255,1)",
    whiteSmoke: "rgba(238,238,238,1)",
    googleGreen: "#34A853",
    squareBlack: "#3E4348",
    facebookBlue: "#4267B2",
  },
  fonts: {
    helveticaBold: {
      family: "Helvetica",
      size: "24px",
      weight: "700",
      lineHeight: "normal",
    },
    helveticaRegular: {
      family: "Helvetica",
      size: "16px",
      weight: "400",
      lineHeight: "30px",
    },
    roboto12Medium: {
      family: "Roboto",
      size: "12px",
      weight: "500",
      lineHeight: "16px",
    },
    roboto16Medium: {
      family: "Roboto",
      size: "16px",
      weight: "500",
      lineHeight: "16px",
    },
    roboto16Regular: {
      family: "Roboto",
      size: "16px",
      weight: "400",
      lineHeight: "24px",
    },
    roboto18Regular: {
      family: "Roboto",
      size: "18px",
      weight: "400",
      lineHeight: "24px",
    },
    roboto24Regular: {
      family: "Roboto",
      size: "24px",
      weight: "400",
      lineHeight: "24px",
    },
    roboto20Bold: {
      family: "Roboto",
      size: "20px",
      weight: "700",
      lineHeight: "normal",
    },
    roboto24Bold: {
      family: "Roboto",
      size: "24px",
      weight: "700",
      lineHeight: "normal",
    },
    helveticaBold: {
      family: "Helvetica",
      size: "24px",
      weight: "700",
      lineHeight: "normal",
    },
    helveticaRegular: {
      family: "Helvetica",
      size: "16px",
      weight: "400",
      lineHeight: "30px",
    },
    inter18Regular: {
      family: "Inter",
      size: "18px",
      weight: "400",
      lineHeight: "24px",
    },
    inter24Regular: {
      family: "Inter",
      size: "24px",
      weight: "400",
      lineHeight: "24px",
    },
    roboto12Thin: {
      family: "Roboto",
      size: "12px",
      weight: "100",
      lineHeight: "24px",
    },
    roboto14Medium: {
      family: "Roboto",
      size: "14px",
      weight: "500",
      lineHeight: "24px",
    },
    roboto14Regular: {
      family: "Roboto",
      size: "14px",
      weight: "400",
      lineHeight: "normal",
    },
    roboto18Bold: {
      family: "Roboto",
      size: "18px",
      weight: "700",
      lineHeight: "8px",
    },
    roboto20Bold: {
      family: "Roboto",
      size: "20px",
      weight: "700",
      lineHeight: "24px",
    },
    roboto20Bold2: {
      family: "Roboto",
      size: "20px",
      weight: "700",
      lineHeight: "normal",
    },
    roboto20Regular: {
      family: "Roboto",
      size: "20px",
      weight: "400",
      lineHeight: "normal",
    },
    roboto22Bold: {
      family: "Roboto",
      size: "22px",
      weight: "700",
      lineHeight: "normal",
    },
    roboto22Light: {
      family: "Roboto",
      size: "22px",
      weight: "300",
      lineHeight: "normal",
    },
    roboto18Light: {
      family: "Roboto",
      size: "18px",
      weight: "300",
      lineHeight: "normal",
    },
    roboto24Bold: {
      family: "Roboto",
      size: "24px",
      weight: "700",
      lineHeight: "normal",
    },
    roboto26Bold: {
      family: "Roboto",
      size: "26px",
      weight: "700",
      lineHeight: "normal",
    },
    roboto31Bold: {
      family: "Roboto",
      size: "31px",
      weight: "700",
      lineHeight: "normal",
    },
    roboto42Bold: {
      family: "Roboto",
      size: "42px",
      weight: "700",
      lineHeight: "normal",
    },
    roboto8Regular: {
      family: "Roboto",
      size: "8px",
      weight: "400",
      lineHeight: "8px",
    },
  },
};
