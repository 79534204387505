import React from 'react';
import styled from 'styled-components'
import useTitle from "hooks/useTitle";

const Iframe = styled.iframe`
  margin-left: 50%;
  max-width: 830px;
  width: 100%;
  height: calc(100vh - 52px);
  border: none;
  transform: translateX(-50%);
`

export default () => {
  useTitle("GDPR Policy");
  return (
    <Iframe src="https://docs.google.com/document/d/e/2PACX-1vRe2yYWFJ9KvByHdp4p2fewnk7LQVvpxRxz878qu7ja7WjD-ogTRNNSHbVccvcH3z1vBmVZ2EKaBwRp/pub?embedded=true" />
  )
}
