import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import AddToCartPill from "components/AddToCartPill";
import { Flex, Flex1 } from "components/Flex";
import ProductModifiers from "components/ProductModifier";
import ProductVariations from "components/ProductVariations";
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import useTitle from "hooks/useTitle";

const PageWidth = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
`;

const Card = styled.div`
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 84px);
  color: #424242;
  background: white;
  box-shadow: 3px 3px 10px rgb(27 33 38 / 16%),
    3px 4px 8px 0 rgb(27 33 38 / 22%);
  border-radius: 42px;
  z-index: 10;
  overflow: hidden;
  padding: 42px;
  margin-top: 20px;  
  margin-bottom: 20px;
  word-break: break-word;
  position: relative;
`;

const Inline = styled.div`
  display: inline;
`;
const Img = styled.img`
  max-width: 100%;
  border-radius: 42px;
`;
const Title = styled.h1`
  margin-top: 0;
`;

const Margin20 = styled.div`
  margin: 20px;
`;

const ResponsiveFlex = styled(Flex)`
  @media (max-width: 480px) {
    flex-flow: column-reverse;
  }
`

const ProductDescription = ({ description }) => (
  <div>
    <b>Description</b>
    <p>{description}</p>
  </div>
);

const ProductDetails = ({ cart, setCart, product, location }) => {
  useTitle(`${product?.itemData?.name} - ${location?.name}`)
  useEffect(() => {
    if (product && window.gtag) {
      window.gtag("event", "view_item", {
        currency: "USD",
        value: 7.77,
        items: [
          {
            item_id: product.id,
            item_name: product.itemData?.name,
            affiliation: location.name,
            currency: "USD",
            item_category: product.category?.categoryData?.name,
            // TODO handle multiple categories? 
            // item_category2: "Adult",
            // item_category3: "Shirts",
            // item_category4: "Crew",
            // item_category5: "Short sleeve",
            item_list_id: "single_item_view",
            item_list_name: "Single Item View",
            item_variant: product.itemData?.variations[0]?.itemVariationData?.name,
            location_id: location.id,
            price: product.itemData?.variations[0]?.itemVariationData?.priceMoney.amount,
            quantity: cart[location.id] ? cart[location.id][product.id] : 0,
          }
        ]
      });
    }
  }, [product])
  const [variationId, setVariationId] = useState(
    product.itemData?.variations[0]?.id
  );
  const [selectedModifierIds, setSelectedModifierIds] = useState(new Immutable.Set([]));
  const variation = product.itemData?.variations.find(
    (v) => v.id === variationId
  );
  const key = [variation.id, ...selectedModifierIds.toArray()].join("-");

  const selectedPremiumModifiers = (product.modifiers || [])
    .map(m => m.modifierListData.modifiers)
    .flat()
    .filter(m => selectedModifierIds.has(m.id) && m.modifierData?.priceMoney?.amount)
  const modifiersPrice = selectedPremiumModifiers.reduce((acc, m) => acc + m.modifierData.priceMoney.amount, 0)
  const step = Number.parseFloat(variation.customAttributeValues?.step) || 1;
  const minAmount =
    Number.parseFloat(variation.customAttributeValues?.min_amount) || 1;
  const maxAmount =
    Number.parseFloat(variation.customAttributeValues?.max_amount) || 9;
  const count = Number.parseFloat(
    (cart[product.location_id] && cart[product.location_id][key]) || 0
  );
  const price = variation?.itemVariationData?.priceMoney
    ? (
        Math.round(variation?.itemVariationData?.priceMoney?.amount + modifiersPrice) / 100
      ).toFixed(2)
    : (Math.round(product?.price * 100) / 100).toFixed(2);
  const addToCart = value => {
    const newValue = Number.parseFloat(value);
    if (newValue === 0) {
      if (Object.keys(cart[product.location_id]).length === 1) {
        setCart({ ...cart, [product.location_id]: undefined });
      } else {
        setCart({
          ...cart,
          [product.location_id]: {
            ...cart[product.location_id],
            [key]: undefined,
          },
        });
      }
    } else {
      setCart({
        ...cart,
        [product.location_id]: {
          ...cart[product.location_id],
          [key]: newValue,
        },
      });
    }
  };
  return (
    <PageWidth>
      <Card>
        <Inline>
          <Link to="/">Home</Link>&nbsp;&gt;&nbsp;
          <Link to={`/shop/${location.id}`}>{location.name}</Link>&nbsp;&gt;&nbsp;
          <b>{product.itemData.name}</b>
        </Inline>
        <br />
        <ResponsiveFlex>
          <Flex1>
            <Title>{product.itemData.name}</Title>
            {product.itemData.description && (
              <ProductDescription description={product.itemData.description} />
            )}
            {product.itemData.variations.length > 1 && (
              <ProductVariations
                variation={variation}
                variations={product.itemData.variations}
                setVariationId={setVariationId}
              />
            )}
            {product.modifiers?.length > 1 && (
              <ProductModifiers
                selectedModifierIds={selectedModifierIds}
                modifiers={product.modifiers}
                onChange={(id) => {
                  if (selectedModifierIds.has(id)) {
                    setSelectedModifierIds(selectedModifierIds.delete(id));
                  } else {
                    setSelectedModifierIds(selectedModifierIds.add(id));
                  }
                }}
              />
            )}
          </Flex1>
          <Flex1>
            <Img src={product.img} alt={product.itemData.name} />
          </Flex1>
        </ResponsiveFlex>
        <Margin20 />
        <AddToCartPill
          step={step}
          price={price}
          minAmount={minAmount}
          maxAmount={maxAmount}
          count={count}
          onChange={addToCart}
        />
      </Card>
    </PageWidth>
  );
};

const ProductDetailsWithFetch = ({ cart, setCart, location, productId }) => {
  const {
    response,
    loading,
    error,
  } = useFetch(`${process.env.REACT_APP_BAM_API_URL}/api/product`, {
    query: { productId, locationId: location.id },
  });
  if (loading) {
    return "Loading";
  }
  if (response) {
    return (
      <ProductDetails
        cart={cart}
        setCart={setCart}
        location={location}
        product={response}
      />
    );
  }
  return "error";
};

const ProductDetailsPage = ({ cart, setCart, locations }) => {
  const { productId, locationId } = useParams();
  const location = locations.find((l) => l.id === locationId);
  if (!location) {
    return "Loading";
  }
  return (
    <ProductDetailsWithFetch
      location={location}
      cart={cart}
      setCart={setCart}
      productId={productId}
    />
  );
};

export default ProductDetailsPage;
