import React from "react";

export const formatPriceLabelWithName = ({ name = "", priceMoney } = {}) => {
  const price = priceMoney
    ? ` - $${(priceMoney.amount / 100).toFixed(2)}`
    : "";
  return name + price;
};

export const PriceLabel = ({ htmlFor="", name = "", modifierData = {} }) => {
  return <label htmlFor={htmlFor} name={name}>{formatPriceLabelWithName(modifierData)}</label>;
};

export default PriceLabel;
