import React, { useRef, useState } from "react";
import styled from "styled-components";
import Hamburger from "icons/Hamburger";
import StarBand from 'components/StarBand';
import { CartPreview } from "components/ShoppingCart";
import { Sidebar } from "components/Sidebar";
import { Search } from "components/SearchResults";
import _throttle from "lodash.throttle";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'; 

export const Toolbar = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow:hidden;
  background-color: ${props => props.theme.colors.bamBlue};
  background-image: url("https://static.overlay-tech.com/assets/cd654d58-3568-44d5-91a5-4105ad0b3c6c.png");
  background-position: center;
`;
export const FlexWrapperOne = styled.div`
  margin-bottom: 12px;
  padding: 0 0 0 1px;
  display: flex;
  align-items: flex-start;
  width: 100%;
`;
export const MenuButton = styled.div`
  margin-right: 49px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Rectangle56 = styled.div`
  width: 78px;
  height: 12px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 3px;
  box-shadow: 0 4px 4px 0
    ${props => props.theme.colors.transparentBlack};
  &:not(:last-of-type) {
    margin-bottom: 13px;
  }
`;
export const Location = styled.div`
  margin-top: 11px;
  margin-right: 101px;
  border-radius: 9px;
  padding: 4px 10px 3px 11px;
  display: flex;
  align-items: center;
  border: 3px solid ${props => props.theme.colors.white};
`;
export const Blip = styled.img`
  margin-right: 11px;
`;
export const Zip = styled.p`
  font-family: ${props =>
    props.theme.fonts.roboto22Bold.family};
  font-size: ${props =>
    props.theme.fonts.roboto22Bold.size};
  font-weight: ${props =>
    props.theme.fonts.roboto22Bold.weight};
  line-height: ${props =>
    props.theme.fonts.roboto22Bold.lineHeight};
  color: ${props => props.theme.colors.white};
  text-align: center;
`;
export const SearchBar = styled.div`
  margin-right: 155px;
  position: relative;
`;
export const Backdrop = styled.div`
  background-color: ${props =>
    props.theme.colors.whiteSmoke};
  border-radius: 40px;
  padding: 0 0 0 376px;
  opacity: 0.76;
  position: relative;
`;
export const Red = styled.div`
  width: 224px;
  height: 61px;
  background-color: ${props =>
    props.theme.colors.fireBrick2};
  border-radius: 0px 30px 30px 0px;
`;
export const Products = styled.p`
  font-family: ${props =>
    props.theme.fonts.roboto31Bold.family};
  font-size: ${props =>
    props.theme.fonts.roboto31Bold.size};
  font-weight: ${props =>
    props.theme.fonts.roboto31Bold.weight};
  line-height: ${props =>
    props.theme.fonts.roboto31Bold.lineHeight};
  color: ${props => props.theme.colors.white};
  text-align: center;
  position: absolute;
  left: 423px;
  top: 13px;
`;
export const MagnifyGlass = styled.img`
  position: absolute;
  left: 30px;
  top: 11px;
`;
export const Cart = styled.div`
  margin-top: 8px;
  margin-right: 25px;
  padding: 0;
  position: relative;
  margin-left: ${props => props.isMobile ? '20px': '0'};
`;
export const CartImage = styled.img`
  position: relative;
`;
export const CartNumber = styled.div`
  background-color: ${props =>
    props.theme.colors.bamDarkRed};
  border-radius: 60px;
  padding: 0 6px 0 7px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  left: 17px;
  top: -4px;
  font-family: ${props =>
    props.theme.fonts.roboto22Bold.family};
  font-size: ${props =>
    props.theme.fonts.roboto22Bold.size};
  font-weight: ${props =>
    props.theme.fonts.roboto22Bold.weight};
  line-height: ${props =>
    props.theme.fonts.roboto22Bold.lineHeight};
  color: ${props => props.theme.colors.white};
`;

export const MyAccount = styled(Link)`
  margin: 10px 20px 0 20px;
  white-space: nowrap;
  font-family: ${props =>
    props.theme.fonts.roboto24Bold.family};
  font-size: ${props =>
    props.theme.fonts.roboto24Bold.size};
  font-weight: ${props =>
    props.theme.fonts.roboto24Bold.weight};
  line-height: ${props =>
    props.theme.fonts.roboto24Bold.lineHeight};
  color: ${props => props.theme.colors.white};
  text-align: center;
  background-color: ${props =>
    props.theme.colors.bamBlue};
  border-radius: 30px;
  padding: 2px 13px;
  display: flex;
  align-items: center;
  border: 2px solid ${props => props.theme.colors.white};
  outline: 0;
`;
const Flex1 = styled.div`flex:1;`
const StyledHamburger=styled(Hamburger)`
    width: 42px;
    margin: 6px 14px 6px 10px;
    height: 42px;
    fill: white;
`
const Header = ({
  numberOfCartItems,
  user,
  isMobile,
  isDesktop,
  cart,
  setCart,
}) => {
  const { loginWithRedirect } = useAuth0();
  const openCartButton = useRef();
  const openSidebarButton = useRef();
  const [isCartDialogOpen, _setIsCartDialogOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  React.useEffect(() => {
    setIsSidebarOpen(false);
  }, [location]);
  const setIsCartDialogOpen = _throttle(_setIsCartDialogOpen, 100, { 'trailing': false }) // Ugly hack to stop sidebar from closing when items removed
  
  let loginButton = <MyAccount className="login" onClick={() => loginWithRedirect()}>Sign In</MyAccount>;
  if (user) loginButton = <MyAccount to="/profile" className="avatar">My Account</MyAccount>;
  if (user?.given_name) loginButton = <MyAccount to="/profile" className="avatar">Hi, {user.given_name}</MyAccount>;
  if (isMobile) loginButton = null;
  return (
    <>
      <Toolbar>
        <FlexWrapperOne>
          <div onClick={() => setIsSidebarOpen(true)} ref={openSidebarButton}>
            <StyledHamburger />
          </div>
          <Flex1 />
          <Search cart={cart} setCart={setCart} />
          <Flex1 /> 
          {loginButton}
          {!isDesktop && (
            <Cart
              isMobile={isMobile}
              className="openCart"
              onClick={() => setIsCartDialogOpen(true)} ref={openCartButton} 
            >
              <CartImage
                alt="cart"
                src="https://static.overlay-tech.com/assets/7230d981-be44-4d90-a230-519524e8def4.svg"
              />
              <CartNumber>
                {numberOfCartItems}
              </CartNumber>
            </Cart>
          )}
        </FlexWrapperOne>
        <StarBand />
      </Toolbar>
      <CartPreview
        isMobile={isMobile}
        isDesktop={isDesktop}
        openButtonRef={openCartButton}
        isOpen={isCartDialogOpen || isDesktop}
        setIsOpen={setIsCartDialogOpen}
        user={user}
        cart={cart}
        setCart={setCart}
      />
      <Sidebar
        isMobile={isMobile}
        openButtonRef={openSidebarButton}
        isOpen={isSidebarOpen}
        user={user}
        close={() => setIsSidebarOpen(false)}
      />
    </>
  );
};

export default Header;